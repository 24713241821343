import { Injectable } from '@angular/core';
import { ConfigData } from '../interfaces/config-data';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as objectPath from 'object-path';
import { Router, NavigationStart } from '@angular/router';
import { MenuConfig } from '../../config/menu';
// badges
import { RequestsService } from '@app/core/services/requests.service';
//
import { AuthenticationService } from '@app/core/auth/authentication.service';

import bugsnagClient from '@app/bugsnag';

@Injectable()
export class MenuConfigService {
	public configModel: MenuConfig = new MenuConfig();
	public onMenuUpdated$: BehaviorSubject<MenuConfig> = new BehaviorSubject(
		this.configModel
	);
	menuHasChanged: any = false;
	currentRole: string;

	constructor(private router: Router,
		private applyRequestsService: RequestsService,
		private authenticationService: AuthenticationService) {

		this.authenticationService.getUserRoles().subscribe(roles => {
			if (!roles || roles.length == 0 ) {
				bugsnagClient.notify(new Error('getUserRoles'), {
					metaData: roles
				});
				return;
			}
			this.currentRole = roles[0];
		});

		this.checkForBadges();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.checkForBadges();
				if (this.menuHasChanged) {
					this.resetModel();
				}
			});
	}

	setModel(menuModel: MenuConfig) {
		this.configModel = Object.assign(this.configModel, menuModel);
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = true;
	}

	resetModel() {
		this.configModel = new MenuConfig();
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = false;
	}

	checkForBadges() {

		if ( this.currentRole == 'ADMIN' ) {
			this.applyRequestsService.getRequests().subscribe(
				(res: any) => {
					let waitingRequests = 0;
					res.forEach((request: any) => {
						waitingRequests = (request.cd_state == 100) ? waitingRequests + 1 : waitingRequests;
					});
					if ( waitingRequests > 0 ) {
						this.configModel.config.aside.items.forEach((item: any) => {
							if (item.page == '/requests') {
								item.badge = { type: 'm-badge--danger', value: `${waitingRequests}` }
							}
						});
						this.setModel(this.configModel);
					}
				}
			);
		}

	}
}
