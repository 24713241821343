import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, BehaviorSubject, of } from 'rxjs';
import { BusinessModel } from '@app/core/models/business.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class BusinessService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
	public businessResult: any;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createRestaurant(restaurant: BusinessModel): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}`;
		return this.http.post<BusinessModel>(url, restaurant);
	}

	getRestaurantInfo(): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_MY_PROFILE}`;
		return this.http.get<BusinessModel>(url);
	}

	/* DISMESSA */
	getRestaurantById(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}`;
		return this.http.get<BusinessModel>(url);
	}

	// READ
	getRestaurants(restaurant: BusinessModel): Observable<BusinessModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}`;
		return this.http.get<BusinessModel[]>(url);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findRestaurants(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateRestaurant(restaurant: BusinessModel): Observable<any> {
		//console.log(JSON.stringify(restaurant));
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurant.uid}`;
		return this.http.put(url, restaurant);
	}

	uploadImage(restaurantId: string, image: File) {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}/profile/image/upload`;
		return this.http.post(url, formData);
	}

	// DELETE => delete the customer from the server
	deleteRestaurant(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}`;
		return this.http.delete<BusinessModel>(url);
	}

	// SET ONLINE => activate the restaurant
	setOnlineRestaurant(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}/online`;
		return this.http.put<BusinessModel>(url, null);
	}

	// SET OFFLINE => activate the restaurant
	setOfflineRestaurant(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}/offline`;
		return this.http.put<BusinessModel>(url, null);
	}

	// SET REACTIVE => activate the restaurant
	setReactiveRestaurant(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}/activate`;
		return this.http.put<BusinessModel>(url, null);
	}

	// SET REACTIVE => activate the restaurant
	setInactiveRestaurant(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}/deactivate`;
		return this.http.put<BusinessModel>(url, null);
	}

	resetPassword(email: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION}/reset-password`;
		return this.http.post(url, { 'ds_email': email });
	}

}
