import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomizationsModel } from '@app/core/models/customizations.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';
//import { CustomizationsDataSource } from '@app/core/models/data-sources/customizations.datasource';

import { API } from '@env/environment';

@Injectable()
export class CustomizationsService {
	//private dataSource: CustomizationsDataSource;
	public customizationsResult: any;
	//public bResult$: Observable<CustomizationsModel[]>;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) {
		/*
		if ( !this.customizationsResult ) {
			//this.loadCustomizations();
			this.bResult$ = this.getCustomizations();
			this.bResult$.subscribe( (res: any) => {
				this.customizationsResult = res;
			})
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createCustomization(idBusiness: string, customization: CustomizationsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_CUSTOMIZATIONS_NO_SLASH}`;
		return this.http.post<CustomizationsModel>(url, customization).pipe(
			map(() => {
				this.getCustomizations(idBusiness).subscribe(
					(result: CustomizationsModel[]) => {
						this.customizationsResult = result;
					}
				);
			})
		);
	}

	// READ
	getCustomizations(businessId: string): Observable<CustomizationsModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${businessId}/${API.API_CUSTOMIZATIONS_NO_SLASH}`;
		return this.http.get<CustomizationsModel[]>(url);
	}

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findCustomizations(idBusiness: string, queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_CUSTOMIZATIONS_NO_SLASH}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateCustomization(idBusiness: string, customization: CustomizationsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_CUSTOMIZATIONS_NO_SLASH}/${customization.id}`;
		return this.http.put(url, customization).pipe(
			map(() => {
				this.getCustomizations(idBusiness).subscribe(
					(result: CustomizationsModel[]) => {
						this.customizationsResult = result;
					}
				);
			})
		);
	}

	// DELETE => delete the customer from the server
	deleteCustomization(idBusiness: string, customizationId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_CUSTOMIZATIONS_NO_SLASH}/${customizationId}`;
		return this.http.delete<CustomizationsModel>(url).pipe(
			map(() => {
				this.getCustomizations(idBusiness).subscribe(
					(result: CustomizationsModel[]) => {
						this.customizationsResult = result;
					}
				);
			})
		);
	}

	getCustomizationsTitleById(customizationId: string, currentLanguage: string) {
		let title: string = '';
		for (const customization of this.customizationsResult) {
			if (customization.id === customizationId) {
				return customization[`ds_title_${currentLanguage}`];
			}
		}
		return title;
	}

	getCustomizationById(customizationId: string) {
		//console.log('getCustomizationById', customizationId, this.customizationsResult);
		let _customization: any;
		for (const customization of this.customizationsResult) {
			if (customization.id === customizationId) {
				return customization;
			}
		}
		return _customization;
	}

	setCustomizationsData(data: any) {
		this.customizationsResult = data;
	}

}
