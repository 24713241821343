import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorage {
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('accessToken');
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('refreshToken');
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = localStorage.getItem('userRoles');
		try {
			return of(JSON.parse(roles));
		} catch (e) {}
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getFirebaseUID(): string {
		const firebaseUID: string = <string>localStorage.getItem('firebaseUID');
		//console.log('getFirebaseUID', firebaseUID);
		return firebaseUID;
	}

	public getFirebaseUserData(): string {
		const fbUserData: any = {
			firebaseUID: <string>localStorage.getItem('firebaseUID'),
			displayName: <string>localStorage.getItem('displayName'),
			email: <string>localStorage.getItem('email'),
			phoneNumber: <string>localStorage.getItem('phoneNumber'),
			emailVerified: <string>localStorage.getItem('emailVerified')
		}
		//console.log('getFirebaseUserData', fbUserData);
		return fbUserData;
	}

	/**
	 * Set credentials
	 * @returns {TokenStorage}
	 */
	public getCredentials(): any {
		const credentials: any =  JSON.parse(localStorage.getItem('credentials'));
		return credentials;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		//console.log('setAccessToken', token);
		localStorage.setItem('accessToken', token);
		return this;
	}

	/**
	 * Set credentials
	 * @returns {TokenStorage}
	 */
	public setCredentials(credentials: any): TokenStorage {
		localStorage.setItem('credentials', JSON.stringify(credentials));
		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		localStorage.setItem('refreshToken', token);
		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setFirebaseUserData(accessData: any): TokenStorage {
		localStorage.setItem('firebaseUID', accessData.firebaseUID);
		localStorage.setItem('displayName', accessData.displayName);
		localStorage.setItem('email', accessData.email);
		localStorage.setItem('phoneNumber', accessData.phoneNumber);
		localStorage.setItem('emailVerified', accessData.emailVerified);
		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			localStorage.setItem('userRoles', JSON.stringify(roles));
		}

		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
		localStorage.removeItem('firebaseUID');
		localStorage.removeItem('displayName');
		localStorage.removeItem('email');
		localStorage.removeItem('phoneNumber');
		localStorage.removeItem('emailVerified');
		//localStorage.removeItem('credentials');
	}

}
