import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { RidersModel } from '@app/core/models/riders.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class RidersService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
	public ridersResult: any;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createRider(rider: RidersModel): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}`;
		return this.http.post<RidersModel>(url, rider);
	}

	// READ
	getRiders(): Observable<RidersModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}`;
		return this.http.get<RidersModel[]>(url);
	}

	getRiderById(riderId: string): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}`;
		return this.http.get<RidersModel>(url);
	}

	getRiderNameById(riderId: string) {
		let name: string = '';
		for (const rider of this.ridersResult) {
			if (rider.id === riderId) {
				return rider[`ds_firstname`] + ' ' + rider[`ds_lastname`];
			}
		}
		return name;
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findRiders(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the rider on the server
	setAvaibility(riderId: string, availability: any): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}/availability`;
		return this.http.put(url, availability);
	}


	// UPDATE => PUT: update the rider on the server
	updateRider(rider: RidersModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${rider.uid}`;
		return this.http.put(url, rider);
	}

	// DELETE => delete the rider from the server
	deleteRider(riderId: string): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}`;
		return this.http.delete<RidersModel>(url);
	}

	// ACTIVATE => activate the rider from the server
	activateRider(riderId: string): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}/activate`;
		return this.http.put<RidersModel>(url, '');
	}

	// DEACTIVATE => deactivate the rider from the server
	deactivateRider(riderId: string): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}/deactivate`;
		return this.http.put<RidersModel>(url, '');
	}

	// ONLINE => activate the rider from the server
	onlineRider(riderId: string): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}/online`;
		return this.http.put<RidersModel>(url, '');
	}

	// OFFLINE => deactivate the rider from the server
	offlineRider(riderId: string): Observable<RidersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_RIDERS}/${riderId}/offline`;
		return this.http.put<RidersModel>(url, '');
	}

	resetPassword(email: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION}/reset-password`;
		return this.http.post(url, { 'ds_email': email });
	}

}
