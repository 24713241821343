import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeedbacksModel } from '@app/core/models/feedbacks.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';
//import { FeedbacksDataSource } from '@app/core/models/data-sources/feedbacks.datasource';

import { API } from '@env/environment';

@Injectable()
export class FeedbacksService {

	constructor(
		private http: HttpClient,
		private httpUtils: HttpUtilsService,
		private auth: AuthenticationService
	) {}

	// READ
	findFeedbacks(): Observable<FeedbacksModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_FEEDBACKS}`;
		return this.http.get<FeedbacksModel[]>(url);
	}

	// UPDATE => PUT: update the customer on the server
	approveFeedback(idFeedback: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_FEEDBACKS}/${idFeedback}/approve`;
		return this.http.put(url, {});
	}

	refuseFeedback(idFeedback: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_FEEDBACKS}/${idFeedback}/refuse`;
		return this.http.put(url, {});
	}

}
