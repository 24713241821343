import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { BusinessModel } from '@app/core/models/business.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class RestaurantsService {

	public restaurantsResult: any;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createRestaurant(restaurant: BusinessModel): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}`;
		return this.http.post<BusinessModel>(url, restaurant);
	}

	// READ
	getRestaurants(): Observable<BusinessModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}`;
		return this.http.get<BusinessModel[]>(url);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findRestaurants(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateRestaurant(restaurant: BusinessModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurant.uid}`;
		return this.http.put(url, restaurant);
	}

	// DELETE => delete the customer from the server
	deleteRestaurant(restaurantId: string): Observable<BusinessModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${restaurantId}`;
		return this.http.delete<BusinessModel>(url);
	}

	getRestaurantNametById(cityId: string) {
		let name: string = '';
		for (const business of this.restaurantsResult) {
			if (business.id === cityId) {
				return business[`ds_businessname`];
			}
		}
		return name;
	}

	getRestaurantById(businessId: string) {
		let _business: any;
		for (const business of this.restaurantsResult) {
			if (business.id === businessId) {
				return business;
			}
		}
		return _business;
	}

}
