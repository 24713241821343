// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: true,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	releaseStage: 'prod',
	/*
	firebase: {
		apiKey: "AIzaSyDp7NFj1R_BOSvKzrHS1ht5P7j-z1GOFFY",
		authDomain: "moovyfood-api-dev.firebaseapp.com",
		databaseURL: "https://moovyfood-api-dev.firebaseio.com",
		projectId: "moovyfood-api-dev",
		storageBucket: "moovyfood-api-dev.appspot.com",
		messagingSenderId: "524788379958"
	}
	*/
	/*
	firebase: {
		apiKey: "AIzaSyDj9L6tXWIKdlIKNOW-PYEP6qwnx5Mfmzg",
		authDomain: "moovyfood-api-test.firebaseapp.com",
		databaseURL: "https://moovyfood-api-test.firebaseio.com",
		projectId: "moovyfood-cms-test",
		storageBucket: "moovyfood-api-test.appspot.com",
		messagingSenderId: "960288865828"
	}
	*/
	
	firebase: {
		apiKey: "AIzaSyCAWFQU7BaxUyjuFRUVZqvBH7j11O9_NI0",
		authDomain: "moovyfood-api-prod.firebaseapp.com",
		databaseURL: "https://moovyfood-api-prod.firebaseio.com",
		projectId: "moovyfood-api-prod",
		storageBucket: "moovyfood-api-prod.appspot.com",
		messagingSenderId: "820314855881",
		appId: "1:820314855881:web:d9f0d88510bc5f9f"
	}
	
};

export const OFFERS_CATEGORY_ID = {
    _local: 'uahItnltQeaRHNGqAhf1',
    dev: 'uahItnltQeaRHNGqAhf1',
    test: 'KdiCEV6gsLBo9BvigfVW',
    prod: 'EOgRIe6QQGsEloe2Q6Qo'
};

export const MF_ORDER_STATUS = {
	WAITING: 100,
	EXPIRED: 200,
	REFUSED_BIZ: 310,
	CANCEL_USER: 320,
	REFUSED_USER: 325,
	CANCEL_MF: 330,
	DELAYED_BIZ: 400,
	ACCEPTED_BIZ: 600,
	ACCEPTED_USER: 610,
	READY: 700,
	PICKED_USER: 750,
	DELIVERING: 800,
	DELIVERED: 900
};

export const MF_RIDER_STATUS = {
	AVAILABLE: 100,
	WAITING_FOR_ORDER_CONFIRM: 310,
	ASSIGNED: 320,
	DELIVERING: 330,
	OFFLINE: 600
};

export const MF_APPLY_STATUS = {
	WAITING: 100,			// GIALLO
	EVALUATION: 200,	// BLUE
	PREAPPROVED: 300, // BLUE
	APPROVED: 400, 		// VERDE
	REFUSED: 500, 		// ROSSO
}

export const gmaps_api = {
	apikey: 'AIzaSyDp7NFj1R_BOSvKzrHS1ht5P7j-z1GOFFY'
};

export const API = {

	//API_URL: 'https://api-dev.moovyfood.net/api',
	API_URL: 'https://api.moovyfood.net/api',
	API_VERSION: '/v1',

	API_ENDPOINT_LOGIN: '/auth',

	// MY PROFILE
	API_MY_PROFILE: '/me',
	// ADMIN SPECIFIC
	// BUSINESS SPECIFIC
	API_BUSINESS_SPECIFIC: '/business',
	// Categories
	API_BUSINESS_SERVICES: '/service',
	API_CUISINE_TYPES: '/cuisine',
	API_DISHES_CATEGORIES: '/category',
	// Requests
	API_REQUESTS: '/apply',
	// Customizations
	API_CUSTOMIZATIONS_NO_SLASH: 'customization',
	// Dishes
	API_DISHES_NO_SLASH: 'dish',
	// Cities
	API_CITY: '/city',
	// API_MEETINGPOINTS
	API_MEETINGPOINTS: '/meetingpoint',
	// RIDERS
	API_RIDERS: '/rider',
	// USERS
	API_USERS: '/user',
	// ORDERS
	API_ORDERS: '/order',
	API_BUSINESS_ORDERS: '/me/orders',
	// SETTINGS
	API_SETTINGS: '/settings',
	// STAFF
	API_STAFF: '/staff',
	// NOTIFICATIONS
	API_SEND_USER_NOTIFICATION: '/send-user-notification',
	// FEEDBACKS
	API_FEEDBACKS: '/feedback',

	// DASHBOARD
	API_DASHBOARD: '/dashboard',

	// STATS
	API_STATS: '/stats'
};
