import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { DishesCategoriesModel } from '@app/core/models/dishes-categories.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

//import { DishesCategoriesDataSource } from '@app/core/models/data-sources/dishes-categories.datasource';

import { API } from '@env/environment';

@Injectable()
export class DishesCategoriesService {

	//private dataSource: DishesCategoriesDataSource;
	public dishesCategoriesResult: any;
	//public bResult$: Observable<DishesCategoriesModel[]>;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
		/*
		if ( !this.dishesCategoriesResult ) {
			//this.loadDishesCategories();
			this.bResult$ = this.getDishesCategories();
			this.bResult$.subscribe( (res: any) => {
				this.dishesCategoriesResult = res;
			})
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createDishesCategory(dishesCategory: DishesCategoriesModel): Observable<DishesCategoriesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES}/${dishesCategory.id}`;
		return this.http.post<DishesCategoriesModel>(url, dishesCategory);
	}

	/*
	private loadDishesCategories() {
		if (this.dishesCategoriesResult) return;
		this.dataSource = new DishesCategoriesDataSource(this);
		const queryParams = new QueryParamsModel({});
		this.dataSource.loadDishesCategories(queryParams);
		this.dataSource.entitySubject.subscribe((res: any) => {
			this.dishesCategoriesResult = res;
			//console.log('loadDishesCategories', res);
		});
	}
	*/

	// READ
	getDishesCategories(): Observable<DishesCategoriesModel[]> {
		return this.http.get<DishesCategoriesModel[]>(API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findDishesCategories(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateDishesCategory(dishesCategory: DishesCategoriesModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES}/${dishesCategory.id}`;
		return this.http.put(url, dishesCategory);
	}

	// DELETE => delete the customer from the server
	deleteDishesCategory(serviceId: string): Observable<DishesCategoriesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES}/${serviceId}`;
		return this.http.delete<DishesCategoriesModel>(url);
	}

	deleteDishesCategories(ids: string[] = []): Observable<any> {
		const url = API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES + '/deletedishescategories';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { dishescategoriesIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}

	// UPDATE Status
	updateStatusForDishesCategories(dishescategories: DishesCategoriesModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			dishescategoriesForUpdate: dishescategories,
			newStatus: status
		};
		const url = API.API_URL + API.API_VERSION + API.API_DISHES_CATEGORIES + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	getCategoryNameById(categoryId: string, currentLanguage: string) {
		let name: string = '';
		for (const dishesCategory of this.dishesCategoriesResult) {
			if ( dishesCategory.id === categoryId ) {
				return  dishesCategory[`ds_name_${currentLanguage}`];
			}
		}
		return name;
	}

	getCategoryById(categoryId: string) {
		let category: any = {};
		for (const dishesCategory of this.dishesCategoriesResult) {
			if ( dishesCategory.id === categoryId ) {
				return  dishesCategory;
			}
		}
		return category;
	}
}
