import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { MeetingPointsModel } from '@app/core/models/meetingpoints.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';
//import { MeetingPointsDataSource } from '@app/core/models/data-sources/meetingPoints.datasource';

import { API } from '@env/environment';

@Injectable()
export class MeetingPointsService {
	//private dataSource: MeetingPointsDataSource;
	public meetingPointsResult: any;
	//public bResult$: Observable<MeetingPointsModel[]>;
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) {
		/*
		if ( !this.meetingPointsResult ) {
			this.bResult$ = this.getMeetingPoints();
			this.bResult$.subscribe( (res: any) => {
				//console.log('MeetingPointsService', res);
				this.meetingPointsResult = res;
			})
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createMeetingPoint(meetingPoint: MeetingPointsModel): Observable<MeetingPointsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_MEETINGPOINTS}`;
		return this.http.post<MeetingPointsModel>(url, meetingPoint);
	}

	/*
	private loadMeetingPoints() {
		if (this.meetingPointsResult) return;
		this.dataSource = new MeetingPointsDataSource(this);
		const queryParams = new QueryParamsModel({});
		this.dataSource.loadMeetingPoints(queryParams);
		this.dataSource.entitySubject.subscribe((res: any) => {
			this.meetingPointsResult = res;
			//console.log('loadMeetingPoints', res);
		});
	}
	*/

	// READ
	getMeetingPoints(): Observable<MeetingPointsModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_MEETINGPOINTS}`;
		//console.log(url);
		return this.http.get<MeetingPointsModel[]>(url);
	}

	// READ
	getMeetingPointById(id: string): Observable<MeetingPointsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_MEETINGPOINTS}/${id}`;
		//console.log(url);
		return this.http.get<MeetingPointsModel>(url);
	}

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findMeetingPoints(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_MEETINGPOINTS}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateMeetingPoint(meetingPointId: string, meetingPoint: MeetingPointsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_MEETINGPOINTS}/${meetingPointId}`;
		return this.http.put(url, meetingPoint);
	}

	// DELETE => delete the customer from the server
	deleteMeetingPoint(meetingPointId: string): Observable<MeetingPointsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_MEETINGPOINTS}/${meetingPointId}`;
		return this.http.delete<MeetingPointsModel>(url);
	}

	getMeetingPointNameById(meetingPointId: string) {

		let title: string = '';
		for (const meetingPoint of this.meetingPointsResult) {
			if (meetingPoint.id === meetingPointId) {
				return meetingPoint[`ds_name`];
			}
		}
		return title;
	}

	getMeetingPointObjectById(meetingPointId: string) {

		let title: string = '';
		for (const meetingPoint of this.meetingPointsResult) {
			if (meetingPoint.id === meetingPointId) {
				return meetingPoint;
			}
		}
		return title;
	}
}
