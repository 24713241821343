import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { SettingsModel } from '@app/core/models/settings.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class SettingsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	public settingsResult: any;

	// READ
	getSettings(): Observable<SettingsModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_SETTINGS}`;
		return this.http.get<SettingsModel[]>(url);
	}

	// UPDATE => PUT: update the rider on the server
	updateSettings(settings: SettingsModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_SETTINGS}`;
		return this.http.put(url, settings);
	}

}
