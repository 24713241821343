import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { API, environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/auth/authentication.service';

import firebase from 'firebase/app';
import 'firebase/messaging';

import bugsnagClient from '@app/bugsnag';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {

	currentMessage = new BehaviorSubject(null);
	messaging: any = {};
	isSupported: boolean = firebase.messaging.isSupported();
	currentRole: string;

	constructor(
		private http: HttpClient,
		private _authenticationService: AuthenticationService,
		private angularFireAuth: AngularFireAuth,
		private angularFireMessaging: AngularFireMessaging) {

		if (this.isSupported) {
			this.messaging = firebase.messaging();

			this._authenticationService.getUserRoles().subscribe(roles => {
				if (!roles || roles.length == 0) {
					bugsnagClient.notify(new Error('getUserRoles'), {
						metaData: roles
					});
					return;
				}
				this.currentRole = roles[0];

				this.angularFireMessaging.messaging.subscribe(
					(_messaging) => {
						_messaging.onMessage = _messaging.onMessage.bind(_messaging);
						_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
					},
					(err: any) => {
						//console.log(err);
					}
				)

			});


		}

	}

	/**
	 * update token in firebase database
	 *
	 * @param userId userId as a key
	 * @param token token as a value
	 */
	private registerDevice(token) {
		const url = `${API.API_URL + API.API_VERSION}/device/register`;
		this.http.post(url, { 'ds_device_token': token }).subscribe((result: any) => {
		}, (err: any) => {
			//console.log(err);
		});
	}

	private unregisterDevice(token) {
		const url = `${API.API_URL + API.API_VERSION}/device/unregister`;
		this.http.post(url, { 'ds_device_token': token }).subscribe((result: any) => {
		}, (err: any) => {
			//console.log(err);
		});
	}

	private subscribeTokenToTopic(token, topic) {
		fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'key=' + environment.firebase.apiKey,
				'Content-Length': '0',
				'Content-Type': 'application/json'
			})
		}).then(response => {
			if (response.status < 200 || response.status >= 400) {
				throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
			}
			//console.log('Subscribed to "' + topic + '"');
		}).catch(error => {
			console.error(error);
		})
	}

	/**
	 * request permission for notification from firebase cloud messaging
	 *
	 * @param userId userId
	 */
	requestPermission() {
		//console.log('requestPermission');
		this.messaging.requestPermission()
			.then(() => {
				//console.log('Notification permission granted.');
				/*
				this.messaging.getToken().then(
					(token: any) => {
						//console.log(token);
						switch (this.currentRole) {
							case 'ADMIN':
								this.subscribeTokenToTopic(token, 'all-admin');
								break;
							case 'STAFF':
								this.subscribeTokenToTopic(token, 'all-staff');
								break;
						}
						this.subscribeTokenToTopic(token, 'all-cms');
					}
				)
				*/
				return this.messaging.getToken();
			})
			.then(token => {
				//console.log(token)
				this.registerDevice(token)
			})
			.catch((err) => {
				//console.log('Unable to get permission to notify.', err);
			});
	}

	unregister() {
		this.angularFireMessaging.requestToken.subscribe(
			(token) => {
				//console.log(token);
				this.unregisterDevice(token);
			},
			(err) => {
				console.error('Unable to get permission to notify.', err);
			}
		);
	}

	receiveMessage() {
		this.messaging.onMessage((payload) => {
			//console.log("Message received. ", payload);
			this.currentMessage.next(payload)
		}, (err: any) => {
			//console.log(err);
		});
	}
}
