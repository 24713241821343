// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: []
			},
			aside: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-globe',
						page: '/',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.DASHBOARD',
						roles: ['ADMIN', 'BUSINESS']
					},
					{section: 'Sections'},
					{
						title: 'Apply Requests',
						desc: 'Some description goes here',
						root: true,
						icon: 'la	la-thumb-tack',
						page: '/requests',
						translate: 'MENU.REQUESTS',
						roles: ['ADMIN']
					},
					{
						title: 'Orders',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-shopping-cart',
						page: '/orders',
						translate: 'MENU.ORDERS',
						roles: ['ADMIN', 'BUSINESS', 'STAFF'],
					},
					{
						title: 'Feedbacks',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-feed',
						page: '/feedbacks',
						translate: 'FEEDBACKS.FEEDBACKS',
						roles: ['ADMIN', 'STAFF'],
					},
					{
						title: 'Restaurant',
						desc: 'Some description goes here',
						root: true,
						icon: 'la 	la-building',
						page: '/business',
						translate: 'MENU.BUSINESS',
						roles: ['BUSINESS']
					},
					{
						title: 'Settings',
						root: true,
						bullet: 'dot',
						icon: 'la la-gears',
						submenu: [
								{
									title: 'Customizations',
									page: '/customizations',
									roles: ['BUSINESS']
								},
								{
									title: 'Dishes',
									page: '/dishes',
									roles: ['BUSINESS']
								},
						],
						roles: ['BUSINESS']
					},
					{
						title: 'Users',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-users',
						page: '/users',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.USERS',
						roles: ['ADMIN', 'STAFF']
					},
					{
						title: 'Restaurants',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-building',
						page: '/restaurants',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.RESTAURANTS',
						roles: ['ADMIN', 'STAFF']
					},
					{
						title: 'Riders',
						desc: 'Some description goes here',
						root: true,
						icon: 'fa fa-bicycle',
						page: '/riders',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.RIDERS',
						roles: ['ADMIN', 'STAFF']
					},
					{
						title: 'Meeting points',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-map-marker',
						translate: 'MENU.MEETINGPOINTS',
						page: '/meetingpoints',
						roles: ['ADMIN']
					},
					{
						title: 'Staff',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-male',
						page: '/staff',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.STAFF',
						roles: ['ADMIN']
					},
					{
						title: 'Stats & Reports',
						desc: 'Some description goes here',
						root: true,
						icon: 'la	la-area-chart',
						page: '/stats',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.STATS',
						roles: ['ADMIN']
					},
					{
						title: 'Settings',
						desc: 'Some description goes here',
						root: true,
						icon: 'la la-gear',
						//page: '/settings',
						//badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.SETTINGS',
						roles: ['ADMIN'],
						submenu: [
								{
									title: 'Cuisine Types',
									page: '/cuisine-types',
									roles: ['ADMIN']
								},
								{
									title: 'Dishes Categories',
									page: '/dishes-categories',
									roles: ['ADMIN']
								},
								{
									title: 'Restaurant Services',
									page: '/business-services',
									roles: ['ADMIN']
								},
								{
									title: 'Cities',
									page: '/cities',
									roles: ['ADMIN']
								},
								{
									title: 'Platform Parameters',
									page: '/settings',
									roles: ['ADMIN']
								},
								{
									title: 'Notifications',
									page: '/notifications',
									roles: ['ADMIN']
								}
						],
					},
				]
			}
		};
	}
}
