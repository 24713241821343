import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { DishesModel } from '@app/core/models/dishes.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class DishesService {

	public dishesResult: any;
	//public bResult$: Observable<DishesModel[]>;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) {
		/*
		if (!this.dishesResult) {
			this.bResult$ = this.getDishes();
			this.bResult$.subscribe( (res: any) => {
				this.dishesResult = res;
			})
		}
		*/

	}

	// CREATE =>  POST: add a new service to the server
	createDish(idBusiness: string, dish: DishesModel): Observable<DishesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}`;
		delete dish.id;
		return this.http.post<DishesModel>(url, dish);
	}

	uploadImage(idBusiness: string, dishId: string, image: File) {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/dish/${dishId}/image/upload`;
		return this.http.post(url, formData);
	}

	getDishById(idBusiness: string, dishId: string): Observable<DishesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}/${dishId}`;
		return this.http.get<DishesModel>(url);
	}

	// READ
	getDishes(businessId: string): Observable<DishesModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${businessId}/${API.API_DISHES_NO_SLASH}`;
		return this.http.get<DishesModel[]>(url);
	}

	setDishesData(data: DishesModel[]) {
		this.dishesResult = data;
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findDishes(idBusiness: string, queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateDish(idBusiness: string, dish: DishesModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}/${dish.id}`;
		delete dish.id;
		return this.http.put(url, dish);
	}

	cloneDish(idBusiness: string, dishId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}/${dishId}/clone`;
		return this.http.put(url, {});
	}

	// DELETE => delete the customer from the server
	deleteDish(idBusiness: string, dishId: string): Observable<DishesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}/${dishId}`;
		return this.http.delete<DishesModel>(url);
	}

	// ACTIVATE => activate the dish
	activateDish(idBusiness: string, dishId: string): Observable<DishesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}/${dishId}/activate`;
		return this.http.put<DishesModel>(url, null);
	}

	// DEACTIVATE => deactivate the dish
	deactivateDish(idBusiness: string, dishId: string): Observable<DishesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${idBusiness}/${API.API_DISHES_NO_SLASH}/${dishId}/deactivate`;
		return this.http.put<DishesModel>(url, null);
	}

}
