import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class DashboardService {

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService) {}

	getDashboard(): Observable<any[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_DASHBOARD}`;
		return this.http.get<any[]>(url);
	}

}
