import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';

import { API } from '@env/environment';

@Injectable()
export class StatsService {

	constructor( private http: HttpClient ) {}

	getOrdersStats(dt_start: string, dt_end: string, id_city: string, id_meeting_point: string, id_business: string): Observable<any[]> {
		let url = `${API.API_URL + API.API_VERSION + API.API_STATS}?dt_start=${dt_start}&dt_end=${dt_end}&pre_type=orders`;
		if ( id_city.length > 0 ) url+=`&id_city=${id_city}`;
		if ( id_meeting_point.length > 0 ) url+=`&id_meeting_point=${id_meeting_point}`;
		if ( id_business.length > 0 ) url+=`&id_business=${id_business}`;

		console.log('getOrdersStats', url);
		return this.http.get<any[]>(url);
	}

	getRidersStats(dt_start: string, dt_end: string, id_rider: string, id_city: string): Observable<any[]> {
		let url = `${API.API_URL + API.API_VERSION + API.API_STATS}?dt_start=${dt_start}&dt_end=${dt_end}&pre_type=riders`;
		if ( id_rider.length > 0 ) url+=`&id_rider=${id_rider}`;
		if ( id_city.length > 0 ) url+=`&id_city=${id_city}`;

		console.log('getRidersStats', url);
		return this.http.get<any[]>(url);
	}

	getFeedbacksStats(dt_start: string, dt_end: string, id_business: string, id_rider: string, id_city: string): Observable<any[]> {
		let url = `${API.API_URL + API.API_VERSION + API.API_STATS}?dt_start=${dt_start}&dt_end=${dt_end}&pre_type=feedbacks`;
		if ( id_business.length > 0 ) url+=`&id_rider=${id_business}`;
		if ( id_rider.length > 0 ) url+=`&id_rider=${id_rider}`;
		if ( id_city.length > 0 ) url+=`&id_city=${id_city}`;

		console.log('getFeedbacksStats', url);
		return this.http.get<any[]>(url);
	}

}
