import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { RequestsModel } from '@app/core/models/requests.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { API } from '@env/environment';

@Injectable()
export class RequestsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// READ
	getRequests(): Observable<RequestsModel[]> {
		return this.http.get<RequestsModel[]>(API.API_URL + API.API_VERSION + API.API_REQUESTS);
	}

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findRequests(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = API.API_URL + API.API_VERSION + API.API_REQUESTS;
		return this.http.get<QueryResultsModel>(url);
	}

	// EVALUATE => PUT: evaluate the requests on the server
	evaluateRequest(requestId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REQUESTS}/${requestId}/evaluate`;
		return this.http.put(url, {});
	}

	// APPROVE => PUT: approve the requests on the server
	preApproveRequest(requestId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REQUESTS}/${requestId}/preapprove`;
		return this.http.put(url, {});
	}

	// APPROVE => PUT: approve the requests on the server
	approveRequest(requestId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REQUESTS}/${requestId}/approve`;
		return this.http.put(url, {});
	}

	// REFUSE => PUT: refuse the requests on the server
	refuseRequest(requestId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REQUESTS}/${requestId}/refuse`;
		return this.http.put(url, {});
	}

	// REFUSE => PUT: refuse the requests on the server
	deleteRequest(requestId: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_REQUESTS}/${requestId}/delete`;
		return this.http.delete(url, {});
	}

}
