import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { API } from '@env/environment';

@Injectable()
export class NotificationsService {

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

	sendNotification(notification: any): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_SEND_USER_NOTIFICATION}`;
		return this.http.post<any>(url, notification);
	}

}
