import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { MenuModel } from '@app/core/models/menu.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

//import { MenuDataSource } from '@app/core/models/data-sources/menu.datasource';
import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class MenuService {

	//private dataSource: MenuDataSource;
	public menuResult: any;
	//public bResult$: Observable<MenuModel[]>;

	constructor(
							private http: HttpClient,
							private httpUtils: HttpUtilsService,
							private authenticationService: AuthenticationService
						) {
							/*
		if ( !this.menuResult ) {
			this.bResult$ = this.getMenu();
			this.bResult$.subscribe( (res: any) => {
				//console.log('MenuService', 'getMenu()');
				this.menuResult = res;
			});
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createMenu(businessId:string, menu: MenuModel): Observable<MenuModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${businessId}/menu`;
		return this.http.post<MenuModel>(url, menu);
	}


	// READ
	getMenu(businessId:string, menuId: string): Observable<MenuModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${businessId}/menu/${menuId}`;
		return this.http.get<MenuModel[]>(url);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findMenu(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateMenu(businessId:string, menuId:string, menu: MenuModel): Observable<any> {
		//console.log(JSON.stringify(menu));
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SPECIFIC}/${businessId}/menu/${menuId}`;
		return this.http.put(url, menu);
	}

	// DELETE => delete the customer from the server
	deleteMenu(serviceId: number): Observable<MenuModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES}/${serviceId}`;
		return this.http.delete<MenuModel>(url);
	}

	getServiceNameById(serviceId: string, currentLanguage: string) {
		let name: string = '';
		for (const service of this.menuResult) {
			if (service.id === serviceId) {
				return service[`name_${currentLanguage}`];
			}
		}
		return name;
	}

	getServiceById(serviceId: string) {
		let _service: any;
		for (const service of this.menuResult) {
			if (service.id === serviceId) {
				return service;
			}
		}
		return _service;
	}

	getServiceImageById(serviceId: string) {
		let image: '';
		for (const service of this.menuResult) {
			if (service.id === serviceId) {
				return service.ds_icon;
			}
		}
		return image;
	}
}
