import { Injectable } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { BusinessService } from '@app/core/services/business.service';
import { CustomizationsService } from "@app/core/services/customizations.service";
import { DishesCategoriesService } from "@app/core/services/dishes-categories.service";
import { BusinessServicesService } from "@app/core/services/business-services.service";
import { CuisineTypesService } from "@app/core/services/cuisine-types.service";
import { CitiesService } from "@app/core/services/cities.service";
import { MenuService } from "@app/core/services/menu.service";
import { DishesService } from "@app/core/services/dishes.service";
import { MeetingPointsService } from "@app/core/services/meetingpoints.service";
import { SettingsService } from "@app/core/services/settings.service";
import { RestaurantsService } from '@app/core/services/restaurants.service';
import { RidersService } from '@app/core/services/riders.service';

import { BusinessModel } from '@app/core/models/business.model';
import { CustomizationsModel } from '@app/core/models/customizations.model';
import { DishesCategoriesModel } from '@app/core/models/dishes-categories.model';
import { BusinessServicesModel } from '@app/core/models/business-services.model';
import { CuisineTypesModel } from '@app/core/models/cuisine-types.model';
import { CitiesModel } from '@app/core/models/cities.model';
import { MenuModel } from '@app/core/models/menu.model';
import { DishesModel } from '@app/core/models/dishes.model';
import { MeetingPointsModel } from '@app/core/models/meetingpoints.model';
import { SettingsModel } from '@app/core/models/settings.model';
import { RidersModel } from '@app/core/models/riders.model';


import bugsnagClient from '@app/bugsnag';

@Injectable({
	providedIn: 'root'
})
export class StaticCollectionsService {
	private ridersObserv$: Observable<RidersModel[]>;
	private restaurantsObserv$: Observable<BusinessModel[]>;
	private businessObserv$: Observable<BusinessModel>;
	private customizationObserv$: Observable<CustomizationsModel[]>;
	private dishesCaegoriesObserv$: Observable<DishesCategoriesModel[]>;
	private servicesObserv$: Observable<BusinessServicesModel[]>;
	private cuisineTypesObserv$: Observable<CuisineTypesModel[]>;
	private citiesObserv$: Observable<CitiesModel[]>;
	private meetingPointsObserv$: Observable<MeetingPointsModel[]>;
	private menuObserv$: Observable<MenuModel[]>;
	private dishesObserv$: Observable<DishesModel[]>;
	private settingsObserv$: Observable<SettingsModel[]>;

	private currentRole: string;
	public collectionReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private authenticationService: AuthenticationService,
		private ridersService: RidersService,
		private businessService: BusinessService,
		private restaurantsService: RestaurantsService,
		private customizationsService: CustomizationsService,
		private dishesCategoriesService: DishesCategoriesService,
		private businessServicesService: BusinessServicesService,
		private cuisineTypesService: CuisineTypesService,
		private citiesService: CitiesService,
		private meetingPointService: MeetingPointsService,
		private menuService: MenuService,
		private dishesService: DishesService,
		private settingsService: SettingsService) {

		this.authenticationService.getUserRoles().subscribe(roles => {
			if (!roles || roles.length == 0 ) {
				bugsnagClient.notify(new Error('getUserRoles'), {
					metaData: roles
				});
				return;
			}
			this.currentRole = roles[0];
			this.fillStaticCollection();
		});

	}

	fillStaticCollection() {
		this.collectionReady.next(false);

		if (this.currentRole == 'ADMIN') {
			this.ridersObserv$ = this.ridersService.getRiders();
			this.restaurantsObserv$ = this.restaurantsService.getRestaurants();
			this.servicesObserv$ = this.businessServicesService.getBusinessService();
			this.cuisineTypesObserv$ = this.cuisineTypesService.getCuisineTypes();
			this.citiesObserv$ = this.citiesService.getCities();
			this.meetingPointsObserv$ = this.meetingPointService.getMeetingPoints();
			this.dishesCaegoriesObserv$ = this.dishesCategoriesService.getDishesCategories();
			this.settingsObserv$ = this.settingsService.getSettings();

			combineLatest(this.ridersObserv$, this.restaurantsObserv$, this.servicesObserv$, this.cuisineTypesObserv$, this.citiesObserv$, this.meetingPointsObserv$, this.dishesCaegoriesObserv$,  this.settingsObserv$,
				(ridersObserv, restaurantsObserv, servicesObserv, cuisineTypesObserv, citiesObserv, meetingPointsObserv, dishesCaegoriesObserv, settingsObserv) => ({
					ridersObserv, restaurantsObserv, servicesObserv, cuisineTypesObserv, citiesObserv, meetingPointsObserv, dishesCaegoriesObserv, settingsObserv
				}))
				.subscribe(pair => {
					//console.log('ADMIN fillStaticCollection', pair);
					this.ridersService.ridersResult = pair.ridersObserv;
					this.restaurantsService.restaurantsResult = pair.restaurantsObserv;
					this.businessServicesService.businessServicesResult = pair.servicesObserv;
					this.cuisineTypesService.cuisineTypesResult = pair.cuisineTypesObserv;
					this.citiesService.citiesResult = pair.citiesObserv;
					this.meetingPointService.meetingPointsResult = pair.meetingPointsObserv;
					this.dishesCategoriesService.dishesCategoriesResult = pair.dishesCaegoriesObserv;
					this.settingsService.settingsResult = pair.settingsObserv;
					this.collectionReady.next(true);
				});
		} else if (this.currentRole == 'STAFF') {

			this.ridersObserv$ = this.ridersService.getRiders();
			this.restaurantsObserv$ = this.restaurantsService.getRestaurants();
			this.servicesObserv$ = this.businessServicesService.getBusinessService();
			this.cuisineTypesObserv$ = this.cuisineTypesService.getCuisineTypes();
			this.citiesObserv$ = this.citiesService.getCities();
			this.meetingPointsObserv$ = this.meetingPointService.getMeetingPoints();
			this.dishesCaegoriesObserv$ = this.dishesCategoriesService.getDishesCategories();

			combineLatest(this.ridersObserv$, this.restaurantsObserv$, this.servicesObserv$, this.cuisineTypesObserv$, this.citiesObserv$, this.meetingPointsObserv$, this.dishesCaegoriesObserv$,
				(ridersObserv, restaurantsObserv, servicesObserv, cuisineTypesObserv, citiesObserv, meetingPointsObserv, dishesCaegoriesObserv) => ({
					ridersObserv, restaurantsObserv, servicesObserv, cuisineTypesObserv, citiesObserv, meetingPointsObserv, dishesCaegoriesObserv
				}))
				.subscribe(pair => {
					//console.log('STAFF fillStaticCollection', pair);
					this.ridersService.ridersResult = pair.ridersObserv;
					this.restaurantsService.restaurantsResult = pair.restaurantsObserv;
					this.businessServicesService.businessServicesResult = pair.servicesObserv;
					this.cuisineTypesService.cuisineTypesResult = pair.cuisineTypesObserv;
					this.citiesService.citiesResult = pair.citiesObserv;
					this.meetingPointService.meetingPointsResult = pair.meetingPointsObserv;
					this.dishesCategoriesService.dishesCategoriesResult = pair.dishesCaegoriesObserv;
					this.collectionReady.next(true);
				});

		} else if (this.currentRole == 'BUSINESS') {

			this.businessObserv$ = this.businessService.getRestaurantInfo();
			this.customizationObserv$ = this.customizationsService.getCustomizations(this.authenticationService.getFirebaseUID());
			this.dishesCaegoriesObserv$ = this.dishesCategoriesService.getDishesCategories();
			this.servicesObserv$ = this.businessServicesService.getBusinessService();
			this.cuisineTypesObserv$ = this.cuisineTypesService.getCuisineTypes();
			this.citiesObserv$ = this.citiesService.getCities();
			this.meetingPointsObserv$ = this.meetingPointService.getMeetingPoints();
			this.dishesObserv$ = this.dishesService.getDishes(this.authenticationService.getFirebaseUID());

			combineLatest(this.businessObserv$, this.customizationObserv$,
				this.dishesCaegoriesObserv$, this.servicesObserv$, this.cuisineTypesObserv$,
				this.citiesObserv$, this.meetingPointsObserv$, this.dishesObserv$,
				(businessObserv, customizationObserv, dishesCaegoriesObserv, servicesObserv, cuisineTypesObserv,
					citiesObserv, meetingPointsObserv, dishesObserv) => ({
						businessObserv, customizationObserv, dishesCaegoriesObserv, servicesObserv, cuisineTypesObserv,
						citiesObserv, meetingPointsObserv, dishesObserv
					}))
				.subscribe(pair => {
					//console.log('BUSINESS fillStaticCollection', pair);
					this.businessService.businessResult = pair.businessObserv;
					this.customizationsService.customizationsResult = pair.customizationObserv;
					this.dishesCategoriesService.dishesCategoriesResult = pair.dishesCaegoriesObserv;
					this.businessServicesService.businessServicesResult = pair.servicesObserv;
					this.cuisineTypesService.cuisineTypesResult = pair.cuisineTypesObserv;
					this.meetingPointService.meetingPointsResult = pair.meetingPointsObserv;
					this.citiesService.citiesResult = pair.citiesObserv;
					this.dishesService.dishesResult = pair.dishesObserv;
					this.collectionReady.next(true);
				});

		}
	}
}
