import { environment } from '@env/environment';
import bugsnag from '@bugsnag/js';

// configure Bugsnag ASAP, before any other imports
const bugsnagClient = bugsnag({
  apiKey: 'f179f546266c2a146f2fffad024cd34b',
  releaseStage: environment.releaseStage
});

export default bugsnagClient;
