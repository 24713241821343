import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { CitiesModel } from '@app/core/models/cities.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';
import { CitiesDataSource } from '@app/core/models/data-sources/cities.datasource';

import { API } from '@env/environment';

@Injectable()
export class CitiesService {
	//private dataSource: CitiesDataSource;
	public citiesResult: any;
	//public bResult$: Observable<CitiesModel[]>;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) {
		/*
		if ( !this.citiesResult ) {
			this.bResult$ = this.getCities();
			this.bResult$.subscribe( (res: any) => {
				//console.log('CitiesService', res);
				this.citiesResult = res;
			})
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createCity(city: CitiesModel): Observable<CitiesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CITY}`;
		return this.http.post<CitiesModel>(url, city);
	}

	/*
	private loadCities() {
		if (this.citiesResult) return;
		this.dataSource = new CitiesDataSource(this);
		const queryParams = new QueryParamsModel({});
		this.dataSource.loadCities(queryParams);
		this.dataSource.entitySubject.subscribe((res: any) => {
			this.citiesResult = res;
			//console.log('loadCities', res);
		});
	}
	*/

	// READ
	getCities(): Observable<CitiesModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CITY}`;
		return this.http.get<CitiesModel[]>(url);
	}

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findCities(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CITY}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateCity(cityId: string, city: CitiesModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CITY}/${cityId}`;
		return this.http.put(url, city);
	}

	// DELETE => delete the customer from the server
	deleteCity(cityId: string): Observable<CitiesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CITY}/${cityId}`;
		return this.http.delete<CitiesModel>(url);
	}

	getCityNameById(cityId: string, currentLanguage: string) {
		let title: string = '';
		for (const city of this.citiesResult) {
			if (city.id === cityId) {
				return city[`ds_name_${currentLanguage}`];
			}
		}
		return title;
	}

	getCityById(cityId: string) {
		let _city: any;
		for (const city of this.citiesResult) {
			if (city.id === cityId) {
				return city;
			}
		}
		return _city;
	}

}
