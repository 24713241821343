import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { BusinessServicesModel } from '@app/core/models/business-services.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

//import { BusinessServicesDataSource } from '@app/core/models/data-sources/business-services.datasource';

import { API } from '@env/environment';

@Injectable()
export class BusinessServicesService {

	//private dataSource: BusinessServicesDataSource;
	public businessServicesResult: any;
	//public bResult$: Observable<BusinessServicesModel[]>;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
		/*
		if ( !this.businessServicesResult ) {
			this.bResult$ = this.getBusinessService();
			this.bResult$.subscribe( (res: any) => {
				//console.log('BusinessServicesService', 'getBusinessService()');
				this.businessServicesResult = res;
			})
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createBusinessService(service: BusinessServicesModel): Observable<BusinessServicesModel> {
		return this.http.post<BusinessServicesModel>(API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES, service);
	}

	// READ
	getBusinessService(): Observable<BusinessServicesModel[]> {
		return this.http.get<BusinessServicesModel[]>(API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findBusinessServices(): Observable<QueryResultsModel> {
		const url = API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateBusinessService(service: BusinessServicesModel): Observable<any> {
		return this.http.put(API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES, service);
	}

	// DELETE => delete the customer from the server
	deleteBusinessService(serviceId: number): Observable<BusinessServicesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_SERVICES}/${serviceId}`;
		return this.http.delete<BusinessServicesModel>(url);
	}

	getServiceNameById(serviceId: string, currentLanguage: string) {
		let name: string = '';
		for (const service of this.businessServicesResult) {
			if (service.id === serviceId) {
				return service[`ds_name_${currentLanguage}`];
			}
		}
		return name;
	}

	getServiceById(serviceId: string) {
		let _service: any;
		for (const service of this.businessServicesResult) {
			if (service.id === serviceId) {
				return service;
			}
		}
		return _service;
	}

	getServiceImageById(serviceId: string) {
		let image: '';
		for (const service of this.businessServicesResult) {
			if (service.id === serviceId) {
				return service.ds_icon;
			}
		}
		return image;
	}
}
