// IT
export const locale = {
	lang: 'it',
	data: {
		TRANSLATOR: {
			SELECT: 'Scegli la tua lingua',
		},
		MENU: {
			NEW: 'Nuovo',
			ACTIONS: 'Azioni',
			CREATE_POST: 'Crea un nuovo post',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Pagina principale',
			ORDERS: 'Ordini',
			STATS: 'Statistiche',
			USERS: 'Utenti',
			RESTAURANTS: 'Ristoranti',
			BUSINESS: 'Business',
			RIDERS: 'Trasportatori',
			STAFF: 'Staff',
			REQUESTS: 'Richieste di iscrizione',
			SETTINGS: 'Impostazioni',
			CUSTOMIZATIONS: 'Varianti piatti',
			MEETINGPOINTS: 'Punti di ritrovo'
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Iscriviti',
				NO_ACCOUNT: 'Non hai un account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Password dimenticata',
				BACK_BUTTON: 'Indietro',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contatti',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Accedi',
				HELP_EMAIL: 'Inserire email',
				HELP_PASSWORD: 'Inserire password',
				PASSWORD: 'Password',
			},
			FORGOT: {
				TITLE: 'Password dimenticata?',
				DESC: 'Inserisci la tua email per resettare la Password',
			},
			VALIDATION: {
				INVALID: '{{name}} non è valido',
				REQUIRED: '{{name}} è richiesto',
				MIN_LENGTH: 'La lunghezza minima del {{name}} è {{min}}',
				AGREEMENT_REQUIRED: 'È necessario accettare I termini e le condizioni contrattuali',
				NOT_FOUND: 'Il nome {{name}} non è stato trovato',
				INVALID_LOGIN: 'I dettagli della Login non sono corretti',
				PASSWORDS_SAME: 'Le password sono identiche',
				PASSWORDS_NOT_SAME: 'Passwords non sono uguali',
				PASSWORD_LEN: 'La Password deve essere lunga almeno 8 caratteri',
				PASSWORD_CONFIRM: 'Conferma password',
				PASSWORD_CONFIRM_HELP: 'Inserisci conferma password',
				PASSWORD_INSERT_CORRECTLY: 'Inserire la password correttamente!',
			}
		},
		FILTERS: {
			ALL: 'tutti',
			BYSTATUS: 'Filtra per status',
			BYTYPE: 'Filtra per tipo',
			ALLFIELDS: 'Cerca in tutti I campi',
			BUSINESS: 'Ristoranti',
			RIDER: 'Trasportatori',
			ONLINE: 'Online',
			OFFLINE: 'Offline',
			BYCATEGORY: 'Filtra per categoria'​​,
			BYCITY: 'Filtra per città',
			BYDATE: 'Filtra per data',
			LAST_WEEK: 'Ultimi 7 giorni',
			LAST_MONTH: 'Questo mese',
			LAST_THREE_MONTHS: 'Ultimi 3 mesi',
			CUSTOM_RANGE: 'Scegli un periodo',
			CLEAR_FILTERS: 'Pulisci filtri',
			NO_DATA_FOR_THIS_FILTER: 'Nessun dato per la sezione fatta'
		},
		HEADTOOLS: {
			BACK: 'Indietro',
			SAVE: 'Salva',
			CLONE: 'Copia',
			ONLINE: 'On line',
			OFFLINE: 'Off line',
			ADD_NEW_DISH: 'Aggiungi un nuovo piatto',
			EXPORT: 'Esporta (.CVS)'
		},
		GLOBAL_FIELDS_NAME: {
			YES: 'Si',
			NO: 'No',
			CANCEL: 'Cancella',
			ICON: 'Icona',
			EMAIL: 'Email',
			EMAIL_HELP: 'Inserisci email pubblica',
			EMAIL_ACCOUNT: 'Email Account',
			EMAIL_ACCOUNT_HELP: 'Inserisci email account',
			PHONE: 'Phone',
			PHONE_HELP: 'Inserisci il tuo numero di telefono',
			LOCATION: 'Luogo',
			LOCATION_HELP: 'Gentilmente inserisci il tuo indirizzo',
			NAME: 'Nome',
			NAME_HELP: 'Pe favore inserisci il tuo nome',
			GENDER: 'Sesso',
			AGE: 'Anni',
			AGE_HELP: 'Inserisci l\'età',
			FIRST_NAME: 'Nome',
			FIRST_NAME_HELP: 'Inserisci il tuo nome',
			LAST_NAME: 'Cognome',
			LAST_NAME_HELP: 'Inserisci il tuo cognome',
			TYPE: 'Tipo',
			DATE: 'Data',
			STATUS: 'Stato',
			RESET_PASSWORD: 'Reset password',
			MALE: 'Uomo',
			FEMALE: 'Donna',
			NORECORDS_FOUND: 'Nessun record trovato',
			ACTIONS: 'Azioni',
			PRICE: 'Prezzo',
			PRICE_HELP: 'Inserisci il prezzo',
			DESCRIPTION: 'Descrizione',
			DESCRIPTION_HELP: 'Inserisci la descrizione',
			RECEPTION_READING: 'Notificato/Letto'
		},
		GLOBAL_ADDRESS_FIELDS: {
			ADDRESS_NAME: 'Indirizzo',
			ADDRESS: 'Insirizzo',
			CITY: 'Città',
			NUMBER: 'Numero',
			PROVINCE: 'Provincia',
			ZIP: 'Zip',
			ADDRESS_HELP: 'Inserisci il tuo indirizzo',
			CITY_HELP: 'Inserisci la tua città',
			NUMBER_HELP: 'Inserisci il tuo numero',
			PROVINCE_HELP: 'Inserisci la tua provincia',
			ZIP_HELP: 'Inserisci il tuo zip',
		},
		WIDGET_HOURS: {

			OPENING_HOURS: 'Orari di apertura:',
			DELIVERY_HOURS: 'Orari di consegna:',
			IMPORT_FROM_DELIVERY_HOURS: 'Importa dagli orari di consegna',
			IMPORT_FROM_OPENING_HOURS: 'Importa dagli orari di apertura',
			OPEN: 'Aperto',
			CLOSED: 'Chiuso',
			COPY: {

				SUCCESS: 'Orario copiato con successo',
				ERROR: 'Orario NON copiato!!!!'
			},
			IMPORT: {

				SUCCESS: 'Orario correttamente importato',
				ERROR: 'Importazione orario fallita'
			}
		},
		DASHBOARD: {},
		REQUESTS: {

			REQUESTS: 'Richieste',
			REQUESTS_LIST: 'Lista richieste',
			FIELDS: {

				TYPE: 'Tipo',
				NAME: 'Nome',
				RESTAURANT_NAME: 'Nome del ristorante',
				PHONE: 'Telefono',
				EMAIL: 'Email',
				STATUS: 'Stato',
				DATE: 'Data',
				ACTIONS: 'Azioni',

			},
			REQUEST_STATUS: {

				WAITING: 'In attesa',
				EVALUATION: 'Valutazione',
				PRE_APPROVED: 'Pre-Approvato',
				APPROVED: 'Approvato',
				REFUSED: 'Rifiutato'
			},
			EVALUATE_REQUEST_SIMPLE: {

				TITLE: 'Valutazione richiesta',
				DESCRIPTION: 'Sei sicuro di voler valutare questa richiesta?',
				WAIT_DESCRIPTION: 'Richiesta in valutazione...',
				MESSAGE: 'La richiesta è stata valutata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			PREAPPROVE_REQUEST_SIMPLE: {

				TITLE: 'Richiesta ore approvata',
				DESCRIPTION: 'Sei sicuro di voler pre-approvare questa richiesta?',
				WAIT_DESCRIPTION: 'La richiesta è in pre-approvazione...',
				MESSAGE: 'La richiesta è stata pre approvata',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			APPROVE_REQUEST_SIMPLE: {

				TITLE: 'Approvazione richiesta',
				DESCRIPTION: 'Sei sicuro di voler approvare questa richiesta?',
				WAIT_DESCRIPTION: 'La richiesta è in approvazione...',
				MESSAGE: 'La richiesta è stata approvata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			REFUSE_REQUEST_SIMPLE: {

				TITLE: 'Rifiuta richiesta',
				DESCRIPTION: 'Sei sicuro di voler rifiutare la richiesta?',
				WAIT_DESCRIPTION: 'La richiesta è in fase di rifiuto...',
				MESSAGE: 'La richiesta è stata rifiutata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			DELETE_REQUEST: {

				TITLE: 'Cancella richiesta',
				DESCRIPTION: 'Sei sicuro di voler cancellare questa richiesta?',
				WAIT_DESCRIPTION: 'La richiesta è in fase di cancellazione...',
				MESSAGE: 'La richiesta è stata cancellata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			}
		},
		BUSINESS: {

			RESTAURANT: 'Ristoranti',
			TABS: {
				PUBLIC_INFO: 'Informazioni pubbliche',
				GALLERY: 'Galleria immagini',
				TIMETABLES: 'Orari',
				PUBLIC_MENU: 'Menù pubblico',
				PUBLIC_ADDRESS: 'Indirizzo pubblico',
				SETTINGS: 'Impostazioni',
				BILLING_INFO: 'Informazioni di fatturazione',
				CUSTOMIZATIONS: 'Varianti',
				DISHES: 'Piatti'
			},
			BUSINESSINFO: {

				BUSINESS_NAME: 'Nome',
				BUSINESS_NAME_HELP: 'Per favore inserisci il nome della tua attività',
				DESCRIPTION: 'Descrizione',
				DESCRIPTION_HELP: 'Per favore inserisci la descrizione',
				IMAGE: 'Immagine',
				IMAGE_UPLOAD_SUCCESS: 'Upload imagine avvenuto con successo!',
				IMAGE_UPLOAD_FAILED: 'Upload imagine non riuscito!',
				IMAGE_UPLOAD_MAX_SIZE: 'La massima dimensione del file è 2MB!',
				IMAGE_UPLOAD_MAX_WIDTH: 'Minimum 1200px!',
				IMAGE_UPLOAD_VERT: 'L\'immagine deve essere orizzontale!',
				IMAGE_UPLOAD_MAX_ITEMS: 'Hai raggiunto il limite massimo di foto per la gallery',
				SELECT_IMAGE: 'Scegliere immagine',
				SERVICES: 'Servizi del ristorante',
				CUISINE_TYPES: 'Tipi di cucina',
				IMAGEBRAND: 'Foto brand',
				SELECT_IMAGE_BRAND: 'Scegliere immagine'
			},
			MENU: {

				DISH: 'piatto',
				ALERT: {

					MISSING_DISHES: 'Piatto mancante! Inserisci il primo',
					PRESS_SAVE: 'Premi SALVA affinché il salvataggio avvenga'
				},
				DISCOUNT_PERCENTAGE: 'Percentuale di sconto',
				DISCOUNT_PERCENTAGE_HELP: 'Inserire la percentuale di sconto',
				START_DISCOUNT_DATE: 'Data di inizio dell\'offerta',
				EXPIRE_DISCOUNT_DATE: 'Data di termine dell\'offerta',
				START_DISCOUNT_DATE_HELP: 'Inserire la data di inizio dell\'offerta',
				EXPIRE_DISCOUNT_DATE_HELP: ' Inserire la data di termine dell\'offerta',

			},
			SETTINGS: {

				MF_PERCENTAGE: 'Percentuali MF',
				MF_PERCENTAGE_HELP: 'Inserire la percentuale a Moovy Food',
				OPTIONS: 'Opzioni',
				CASH_ON_DELIVERY: 'Cash on delivery',
				PICK_AND_PAY: 'Ritira e paga',
				MF_DELIVERY: 'Spedizione Moovy Food',
				SHIPPING_COST: 'Costi di spedizione (L):',
				SHIPPING_COST_HELP: 'Inserire I costi di spedizione (L)',
				MINIMUM_ORDER: 'Ordine minimo (L):',
				MINIMUM_ORDER_HELP: 'Inserire l\'ordine minimo',
				PREPARATION_TIME: 'Tempo di preparazione (min):',
				PREPARATION_TIME_HELP: 'Inserire il tempo di preparazione',
				ORDER_PREFIX: 'Prefisso d\'ordine:',
				ORDER_PREFIX_HELP: 'Inserire il prefisso d\'ordine',
				MEETINGPOINT: 'Punto di incontro',
				MEETINGPOINT_HELP: 'Scegliere il punto di incontro'
			},
			BILLINGINFO: {

				BUSINESS_NAME: 'Nome del business:',
				BUSINESS_NAME_HELP: 'Inserire il nome del business',
				VAT: 'Vat:',
				VAT_HELP: 'Inserire vat',
				REFERENCE_PERSON: 'Nome complete della persona di riferimento:',
				REFERENCE_PERSON_HELP: 'Inserire nome complete della persona di riferimento',

			},
			DISH: {

				EDIT: {

					UPDATE_MESSAGE: 'Il piatto è stato caricato',
					ADD_MESSAGE: 'Il piatto è stato creato'
				},

			},
			ACTIVATE: {

				TITLE: 'Attivare business',
				DESCRIPTION: 'Sei sicuro di voler attivare questo business?',
				WAIT_DESCRIPTION: 'Sto attivando il business...',
				MESSAGE: 'Il business è stato attivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			DEACTIVATE: {

				TITLE: 'Disattiva business',
				DESCRIPTION: 'Sei sicuro di voler disattivare questo business?',
				WAIT_DESCRIPTION: 'Sto disattivando il Business...',
				MESSAGE: 'Il business è stato disattivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			RESET_PASSWORD: {

				TITLE: 'Resetta la Password dell\'utente',
				DESCRIPTION: 'Sei sicuro di voler resettare la password?',
				WAIT_DESCRIPTION: 'Sto inviando la mail...',
				MESSAGE: 'l\'email è stata inviata con successo',
				ERROR: 'Reset password fallito, assicurati che questa email appartenga ad un account MF valido.',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			UPDATE: {

				SUCCESS: 'Il business è stato salvato con successo',
				ERROR: 'Update del business non riuscito'
			},
			CREATE: {

				SUCCESS: 'Il business è stato creato con successo.',
				ERROR: 'Creazione del business fallita'
			},

		},
		SERVICES: {

			SERVICES_LIST: 'Lista servizi'
		},
		CUSTOMIZATIONS: {

			CUSTOMIZATIONS: 'Customizzazioni',
			CUSTOMIZATIONS_LIST: 'Lista customizzazioni',
			FIELDS: {

				TITLE: 'Titolo',
				TITLE_HELP: 'Inserire titolo',
				TYPE: 'Tipo',
				REQUIRED: 'Richiesto',
				TEXT: 'Testo',
				MULTI: 'Multi',
				SINGLE: 'Singolo'
			},
			DELETE_CUSTOMIZATION_SIMPLE: {

				TITLE: 'Cancella customizzazione',
				DESCRIPTION: 'Sei sicuro di voler cancellare la customizzazione?',
				WAIT_DESCRIPTION: 'Sto cancellando la customizzazione...',
				MESSAGE: 'La customizzazione è stata cancellata'
			},
			EDIT: {

				UPDATE_MESSAGE: 'La customizzazione è stata caricata',
				ADD_MESSAGE: 'La customizzazione è stata creata'
			},
			NEW_CUSTOMIZATION: 'Nuova customizzazione',
			DETAILS: {

				CUSTOMIZATION_NAME_: 'Modifica {{name}}',
				NEW_CUSTOMIZATION: 'Nuova customizzazione'
			}
		},
		DISHES: {

			DISHES: 'Piatti',
			DISHES_LIST: 'Lista piatti',
			SELECT_DISH: 'Scelta piatti',
			FIELDS: {

				EXPIRE_DISCOUNT: 'Data di fine offerta',
				CATEGORY: 'Categoria',
				CATEGORY_HELP: 'Inserire categoria',
				DISH: 'Piatto',
				DISH_HELP: 'Inserire piatto',
				DISH_PRICE: 'Prezzo piatto',
				DISH_PRICE_HELP: 'Inserire prezzo piatto',
				DISCOUNT_PRICE: 'Sconto',
				DISCOUNT_PRICE_HELP: 'Inserire sconto',
				START_DISCOUNT_DATE: 'Data di inizio sconto',
				EXPIRE_DISCOUNT_DATE: 'Data di fine sconto',
				START_DISCOUNT_DATE_HELP: 'Inserire data di inizio sconto',
				EXPIRE_DISCOUNT_DATE_HELP: 'Inserire data di fine sconto',
				DEFAULT_PRICE: 'Prezzo di default'
			},
			DETAILS: {

				IMAGE: 'Immagine',
				IMAGE_UPLOAD_SUCCESS: 'Upload imagine avvenuto con successo!',
				IMAGE_UPLOAD_FAILED: 'Upload imagine fallito!',
				IMAGE_UPLOAD_MAX_SIZE: 'La massima dimensione del file è 2 Mb',
				IMAGE_UPLOAD_MAX_WIDTH: 'Massimo 1200px!',
				SELECT_IMAGE: 'Scegli immagine',
				AVAILABLE_FOR_ORDER: 'Disponibile per l\'ordine',
				SERVICES: 'Servizi',
				DISHES_CATEGORY: 'Categorie piatti',
				DISHES_CATEGORY_HELP: 'Inserisci categoria piatti',
				CUSTOMIZATION: 'Customizzazioni',
				DISH_NAME_: 'Modifica piatto {{name}}',

			},
			DELETE_DISH_SIMPLE: {

				TITLE: 'Cancella piatto',
				DESCRIPTION: 'Sei sicuro di voler cancellare il piatto?',
				WAIT_DESCRIPTION: 'Sto cancellando il piatto...',
				MESSAGE: 'Il piatto è stato cancellato'
			},
			CLONE: {

				TITLE: 'Copia piatto',
				DESCRIPTION: 'Sei sicuro di voler copiare questo piatto?',
				WAIT_DESCRIPTION: 'Sto copiando il piatto...',
				MESSAGE: 'Il piatto è stato copiato',
				YESBUTTON: 'Copia',
				NOBUTTON: 'Cancella',
				ERROR: 'Copia piatto fallita'
			},
			UPDATE: {

				SUCCESS: 'Il piatto è stato aggiornato con successo',
				ERROR: 'Aggiornamento piatto non risucito'
			},
			EDIT: {

				UPDATE_MESSAGE: 'Il piatto è stato aggiornato',
				ADD_MESSAGE: 'Il piatto è stato creato'
			},
			ACTIVATE: {

				TITLE: 'Attivare piatto',
				DESCRIPTION: 'Sei sicuro di voler attivare questo piatto?',
				WAIT_DESCRIPTION: 'Sto attivando il piatto...',
				MESSAGE: 'Il piatto è stato attivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			DEACTIVATE: {

				TITLE: 'Disattivazione piatto',
				DESCRIPTION: 'Sei sixcuro di voler disattivare questo piatto?',
				WAIT_DESCRIPTION: 'Sto disattivando il piatto...',
				MESSAGE: 'Il piatto è stato disattivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			NEW_DISH: 'Nuovo piatto'
		},
		RIDERS: {

			RIDERS: 'Trasportatori',
			RIDERS_LIST: 'Lista trasportatori',
			AVAILABILITY: 'Disponibilità',
			STATUS: {

				AVAILABLE: 'Disponibile',
				WAITING: 'In attesa',
				ASSIGNED: 'Assegnato',
				DELIVERING: 'Sta consegnando'
			},
			DETAILS: {

				EDIT_RIDER_: 'Modifica rider {{firstname}} {{lastname}}',
				NEW_RIDER: 'Nuovo rider',
				MEETINGPOINT: 'Punto di raccolta',
				MEETINGPOINT_HELP: 'Inserire punto di raccolta',
				TRANSPORT: 'Tipo di mezzo di trasporto',
				TRANSPORT_HELP: 'Inserire tipo di mezzo di trasporto',
				BIKE: 'Bici',
				SCOOTER: 'Scooter'
			},
			DELETE_RIDER_SIMPLE: {

				TITLE: 'Cancella trasportatore',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo trasportatore?',
				WAIT_DESCRIPTION: 'Sto cancellando il trasportatore...',
				MESSAGE: 'Il trasportatore è stato cancellato'
			},
			ACTIVATE: {

				TITLE: 'Attivare trasportatore',
				DESCRIPTION: 'Sei sicuro di voler attivare questo trasportatore?',
				WAIT_DESCRIPTION: 'Sto attivando il trasportatore...',
				MESSAGE: 'Il trasportatore è stato attivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			DEACTIVATE: {

				TITLE: 'Disattivare trasportatore',
				DESCRIPTION: 'Sei sicuro di voler disattivare un trasportatore?',
				WAIT_DESCRIPTION: 'Sto disattivando il trasportatore...',
				MESSAGE: 'Il trasportatore è statoo disattivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			ONLINE: {

				TITLE: 'Metti trasportatore online',
				DESCRIPTION: 'Sei sicuro di voler mettere online questo trasportatore?',
				WAIT_DESCRIPTION: 'Sto mettendo online il trasportatore...',
				MESSAGE: 'Il trasportatore è stato messo online',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			OFFLINE: {

				TITLE: 'Metti il trasportatore offline',
				DESCRIPTION: 'Sei sicuro di voler mettere il trasportatore offline?',
				WAIT_DESCRIPTION: 'Sto mettendo offline il trasportatore...',
				MESSAGE: 'Il trasportatore è stato messo offline',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			RESET_PASSWORD: {

				TITLE: 'Resettare password utente',
				DESCRIPTION: 'Sei sicuro di voler resettare la password?',
				WAIT_DESCRIPTION: 'Sto inviando un\'email...',
				MESSAGE: 'L\'email è stata inviata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Reset password fallito, assicurati che questa email appartenga ad un account MF valido.'
			},
			UPDATE: {

				SUCCESS: 'Il trasportatore è stato salvato con successo.',
				ERROR: 'Aggiornamento rifer fallito',

			},
			PENDING_DELIVERIES: {

				ID_ORDER_HUMAN: 'Ordini',
				ORDER_TOTAL: 'Totale',
				BUSINESS_NAME: 'Nome del business',
				BUSINESS_PHONE: 'Telefono del business',
				USER_PHONE: 'Telefono dell\'utente',
				DELIVERY_FROM: 'Ritiro da',
				DELIVERY_TO: 'Consegna a',
				DURATION: 'Durata',
				NO_PENDING: 'Non ci sono spedizioni attive per questo rider',


			}
		},
		USERS: {

			USERS: 'Utenti',
			USERS_LIST: 'Lista utenti',
			FIELDS: {

				NAME: 'Nome',
				PHONE: 'Telefono',
				EMAIL: 'Email',
				ACTIONS: 'Azioni',

			},
			DETAILS: {

				NEW_USER: 'Nuovo utente',
				EDIT_USER_: 'Modifica utente {{firstname}} {{lastname}}',
				ADDRESS_TITLE: 'Indirizzo',

			},
			UPDATE: {

				SUCCESS: 'L\'utente è stato aggiornato con successo',
				ERROR: "L'utente non è stato aggiornato",

			},
			DELETE: {

				TITLE: 'Cancella utente',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo utente?',
				WAIT_DESCRIPTION: 'Sto cancellando l\'utente...',
				MESSAGE: "L'utente è stato cancellato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione utenti fallita'
			},
			ACTIVATE: {

				TITLE: 'Attiva utente',
				DESCRIPTION: 'Sei sicuro di voler attivare questo utenti?',
				WAIT_DESCRIPTION: 'Sto attivando l\'utente...',
				MESSAGE: "L'utente è stato attivato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Attivazione utente non riuscita'
			},
			DEACTIVATE: {

				TITLE: 'Disattivazione utente',
				DESCRIPTION: "Sei sicuro di voler disattivare l'utente?",
				WAIT_DESCRIPTION: "Sto disattivando l'utente...",
				MESSAGE: "L'utente è stato disattivato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Disattivazione utenti non risucita'
			},
			RESET_PASSWORD: {

				TITLE: 'Resettare password utente',
				DESCRIPTION: 'Sei sicuro di voler resettare la password?',
				WAIT_DESCRIPTION: 'Sto inviando un email...',
				MESSAGE: 'Un email è stat inviata al tuo indirizzo',
				ERROR: 'Reset password fallito, assicurati che questa email appartenga ad un account MF valido.',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},

		},
		STAFF: {

			STAFF: 'Staff',
			STAFF_LIST: 'Lista staff',
			DETAILS: {

				EDIT_STAFF_: 'Modifica staff {{firstname}} {{lastname}}',
				NEW_STAFF: 'Nuovo membro dello staff'
			},
			DELETE: {

				TITLE: 'Cancella Staff',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo membro dello staff?',
				WAIT_DESCRIPTION: 'Sto cancellando il membro dello staff...',
				MESSAGE: 'Il membro dello staff è stato cancellato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione membro dello staff fallita'
			},
			ENABLE: {

				TITLE: 'Attiva membro dello staff',
				DESCRIPTION: 'Sei sicuro di voler attivare questo membro dello staff?',
				WAIT_DESCRIPTION: 'Sto attivando il membro dello staff...',
				MESSAGE: 'Il membro dello staff è stato attivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Attivazione membro staff fallita'
			},
			DISABLE: {

				TITLE: 'Disattiva membro dello staff',
				DESCRIPTION: 'Sei sicuro di voler disattivare questo membro dello staff?',
				WAIT_DESCRIPTION: 'Sto disattivando il membro dello staff...',
				MESSAGE: 'Il membro dello staff è stato disattivato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Disattivazione membro dello staff fallita'
			},
			RESET_PASSWORD: {

				TITLE: 'Resettare password utente',
				DESCRIPTION: 'Sei sicuro di voler resettare la password?',
				WAIT_DESCRIPTION: 'Sto inviando un email...',
				MESSAGE: "L'email è 'stata inviata",
				ERROR: 'Reset password fallito, assicurati che questa email appartenga ad un account MF valido.',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella'
			},
			UPDATE: {

				SUCCESS: 'Il membro dello staff è stato salvato con successo.',
				ERROR: 'Aggiornamento membro dello staff fallito'
			},
			CREATE: {

				SUCCESS: 'Il membro dello staff è stato creato con successo.',
				ERROR: 'Creazione del membro dello staff fallita'
			},

		},
		ORDERS: {

			ORDERS: 'Ordini',
			ORDERS_LIST: 'Lista ordini',
			ORDER_STATUS: {

				ALL: 'TUTTI',
				WAITING: 'IN ATTESA',
				EXPIRED: 'SCADUTO',
				REFUSED_RESTAURANT: 'RIFIUTATO DAL RISTORANTE',
				CANCEL_USER: "CANCELLATO DALL'UTENTE",
				REFUSED_USER: "RIFIUTATO DALL'UTENTI",
				CANCEL_MF: 'CANCELLATO DA ADMIN/STAFF',
				DELAYED_RESTAURANT: 'IN RITARDO DAL RISTORANTE',
				ACCEPTED_RESTAURANT: 'ACCETTATO DAL RISTORANTE',
				ACCEPTED_USER: "ACCETTATO DALL'UTENTE",
				READY: 'PRONTO',
				PICKED_USER: "RITIRATO DALL'UTENTE",
				DELIVERING: 'IN SPEDIZIONE',
				DELIVERED: 'CONSEGNATO',
				NEEDS_RIDER: 'CHE NECESSITANO TRASPORTATORI'
			},
			FIELDS: {

				ORDER: '#Ordine',
				CUSTOMER: 'Cliente',
				RESTAURANT: 'Ristorante',
				DATE: 'Data',
				PRICE: 'Prezzo (L)',
				STATUS: 'Stato',
				ACTIONS: 'Azioni',

			},
			DELETE: {

				TITLE: 'Cancella ordine',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo ordine?',
				WAIT_DESCRIPTION: "Sto cancellando l'ordine...",
				MESSAGE: "L'ordine è stato cancellato",
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: "Cancellazione d'ordine non riuscita"
			},
			ASSIGN_RIDER: {

				SUCCESS: 'Il trasportatore è stato assegnato con successo.',
				ERROR: "Il trasportatore non è stato assegnato."
			},
			DETAILS: {

				NEW_ORDER: 'Nuovo ordine',
				ORDER_N: 'Ordine {{number}}',
				NEEDS_RIDER: 'Questo ordine ha bisogno di un rider!',
				GENERAL_INFO: 'Informazioni generali',
				EXPECTED_WITHDRAWAL: 'Ritiro previsto',
				EXCPECTED_DELIVERY: 'Spedizione prevista',
				SHIPPING_COST: 'Spese di spedizione',
				TOTAL_PRICE: 'Prezzo totale',
				DUTY_COST: 'Costo di servizio',
				PICKED_BY_CUSTOMER: 'Ritirato dal cliente',
				DISHES: 'Piatti',
				SHIPPING_INFO: 'Informazioni di spedizione',
				SELECT_RIDER: 'Scegli il trasportatore',
				RECEIVED: 'Notificato',
				READ: 'Letto'

			}
		},
		RESTAURANTS: {

			RESTAURANTS_LIST: 'Lista ristoranti',
			DELETE: {

				TITLE: 'Cancella ristorante',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo ristorante?',
				WAIT_DESCRIPTION: 'Sto cancellando il ristorante...',
				MESSAGE: 'Il ristorante è stato cancellato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione ristorante fallita'
			},

		},
		PREPARE: {

			IS_MISSING: 'è perso',

		},
		MEETINGPOINTS: {

			MEETINGPOINTS_LIST: 'Lista punti di raccolta',
			DETAILS: {

				MEETINGPOINT_NAME_: 'Modifica punto di raccolta {{name}}',
				NEW_MEETINGPOINT: 'Nuovo punto di raccolta',

			},
			CREATE: {

				SUCCESS: 'Il punto di raccolta è stato creato con successo',
				ERROR: 'Creazione del punto di raccolta fallita',

			},
			UPDATE: {

				SUCCESS: 'Il punto di raccolta è stato aggiornato con successo',
				ERROR: 'Aggiornamento punto di raccolta fallito',

			},
			DELETE: {

				TITLE: 'Cancella punto di raccolta ',
				DESCRIPTION: 'Sei sicuro di voler cancellare questo punto di raccolta?',
				WAIT_DESCRIPTION: 'Sto cancellando il punto di raccolta...',
				MESSAGE: 'Il punto di raccolta è stato cancellato',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'Cancellazione punto di raccolta fallita'
			},

		},
		CITIES: {

			CITIES_LIST: 'Lista città',
			DETAILS: {

				NEW_CITY: 'Nuova città',
				CITY_NAME_: 'Modifica città {{name}}',

			},
			CREATE: {

				SUCCESS: "La città è stata create con successo",
				ERROR: 'Creazione città fallita',

			},
			UPDATE: {

				SUCCESS: 'La città è stata aggiornata con successo',
				ERROR: ' Aggiornamento città fallito',

			},
			DELETE: {

				TITLE: 'Cancella città',
				DESCRIPTION: 'Sei sicuro di voler cancellare la città?',
				WAIT_DESCRIPTION: 'Sto cancellando la città...',
				MESSAGE: 'La città è stata cancellata',
				YESBUTTON: 'Conferma',
				NOBUTTON: 'Cancella',
				ERROR: 'La città non è stata cancellata'
			},

		},
		CUISINE_TYPES: {
			CUISINE_TYPES: 'Tipo di cucina',
			CUISINE_TYPES_LIST: 'Lista tipi di cucina',
			ISFOREMOST: 'Mandatoria',
			ISACTIVE: 'Attivo',
			DETAILS: {

				CUISINE_TYPES_NAME_: 'Modifica {{name}}',
				NEW_CUISINE_TYPES: 'Nuovo tipo di cucina'
			},
			DELETE_CUISINE_TYPE_SIMPLE: {

				TITLE: 'Cancella tipo di cucina',
				DESCRIPTION: "Sei sicuro di voler canellare questo tipo di cucina?",
				WAIT_DESCRIPTION: 'Sto cancellando il tipo di cucina...',
				MESSAGE: 'Il tipo di cucina è stato cancellato'
			},
			DELETE_CUISINE_TYPE_MULTY: {

				TITLE: 'Cancellare tippi di cucina',
				DESCRIPTION: 'Sei sicuro di voler cancellare questi tipi di cucina?',
				WAIT_DESCRIPTION: 'Sto cancellando tutti I tipi di cucina...',
				MESSAGE: 'I tipi di cucina selezionati sono stati cancellati'
			},
			UPDATE_STATUS: {

				TITLE: 'Il tipo di cucina è stato aggiornato',
				MESSAGE: 'Il tipo di cucina selezionato è stato aggiornato'
			},
			EDIT: {

				UPDATE_MESSAGE: 'Il tipo di cucina è stato aggiornato',
				ADD_MESSAGE: 'Il tipo di cucina è stato creato'
			},
			NEW_CUISINE_TYPE: 'Nuovo tipo di cucina'
		},
		DISHES_CATEGORIES: {

			DISHES_CATEGORIES: 'Categorie dei piatti',
			DISHES_CATEGORIES_LIST: 'Lista categorie dei piatti',
			DISH_CATEGORY: 'Dish category',
			DELETE_DISHES_CATEGORY_SIMPLE: {

				TITLE: 'Cancella categoria dei piatti',
				DESCRIPTION: 'Sei sicuro di voler cancellare questa categoria dei piatti?',
				WAIT_DESCRIPTION: 'Sto cancellando la categoria dei piatti...',
				MESSAGE: 'La categoria dei piatti è stata cancellata'
			},
			DELETE_DISHES_CATEGORY_MULTY: {

				TITLE: 'Cancella le categorie dei piatti',
				DESCRIPTION: 'Sei sicuro di voler cancellare le categorie dei piatti selezionate?',
				WAIT_DESCRIPTION: 'Sto cancellando le categorie dei piatti...',
				MESSAGE: 'Le categorie dei piatti selezionate sono state cancellate'
			},
			UPDATE: {

				TITLE: 'Aggiorna categoria dei piatti',
				MESSAGE: 'La categoria dei piatti è stata aggiornata',
				ERROR: 'Aggiornamento della categoria dei piatti fallita'
			},
			EDIT: {

				UPDATE_MESSAGE: 'La categoria dei piatti è stata aggiornata',
				ADD_MESSAGE: 'La categoria dei piatti è stata creata'
			},
			CREATE: {

				SUCCESS: 'La categoria dei piatti è stata creata',

			},
			NEW_DISHES_CATEGORY: 'Nuova categoria dei piatti',
			DETAILS: {

				DISH_CATEGORY_NAME_: 'Modifica {{name}}',
				NEW_DISH_CATEGORY: 'Nuova categoria dei piatti',

			},
			FIELDS: {

				TITLE: 'Titolo',

			}
		},
		PLATFORM_PARAMETERS: {

			PLATFORM_PARAMETERS: 'Parametri della piattaforma',
			SHIPPING_COST_PERCENTAGE: 'Percentuale dei costi di spedizione',
			DELIVERY_DISTANCE_COST: 'Costi di spedizione su distanza',
			RIDERS_HOURS: 'Orari dei trasportatori',
			FIELDS: {
				CITY_SELECT: 'Seleziona una città',
				DELIVERY_WITH_MF: 'Spedizione con MF',
				DELIVERY_WITHOUT_MF: 'Spedizione senza MF',
				FROM: 'Da',
				TO: 'a',
				COST: 'Costo',
				MEETINGPOINT: 'Punto di ritrovo',
				MEETINGPOINT_HELP: 'Scegliere il punto di ritrovo'
			}
		},
		PLACEHOLDERS: {
			AGE: 'Inserire età',
			FULLNAME: 'Inserire nome completo',
			EMAIL: 'Inserire indirizzo email',
			SELECT_IMAGE: 'Scegliere immagine',
			PASSWORD: 'Inserire password',
			CONFIRM_PASSWORD: 'Inserire nuovamente password',
			DESCRIPTION: 'Inserire descrizione',
			NAME: 'Inserire nome',
			ZIP: 'Inserire zip',
			PROVINCE: 'Inserire provincia',
			CITY: 'Inserire città',
			CIVIC_NUMBER: 'Inserire numero civico',
			ADDRESS: 'Inserire indirizzo',
			VAT: 'Inserire VAT',
			BUSINESS_NAME: 'Inserire nome business',
			PHONE: 'Inserire numero di telefono',
			CUISINE_TYPES: 'Inserire tipo di cucina',
			FROM: 'Da',
			TO: 'A',
			DISCOUNT_PRICE: 'Prezzo di sconto',
			DATE: 'Scegliere una data',
			MF_PERCENTAGE: 'Inserire percentuale MF',
			SHIPPING_COST: 'Inserire costi di spedizione',
			MIN_ORDER: 'Inserire ordine minimo',
			PREPARATION_TIME: 'Inserire tempo di preparazione',
			ORDER_PREFIX: "Inserire prefisso d'ordine",
			MEETINGPOINT: "Scegliere punto d'incontro",
			FIRSTNAME: 'Inserire nome',
			LASTNAME: 'Inserire cognome',
			TITLE: 'Inserire titolo',
			TYPE: 'Inserire tipologia',
			PRICE: 'Inserire prezzo',
			SEARCH: 'Cerca',
			CUSTOMIZAZION: 'Inserire customizzazione',
			DISHES_CATEGORY: 'Inserire categoria piatti',
			DISHES: 'Inserire piatti',
			RIDER: 'Inserire trasportatore',
			PERCENTAGE_WITH_MF: 'Inserire percentuale con MF',
			PERCENTAGE_WITHOUT_MF: 'Inserire percentuale senza MF',
			DISH_NAME: 'Inserire nome piatto',
			DISH_CATEGORY: 'Inserire categoria piatto',
			DISH_PRICE: 'Inserire Prezzo piatto'​​,
			MESSAGE: 'Inserire messaggio',
			GENDER: 'Inserire sesso',
		},
		PREFERRED_LANG: 'Lingua preferita',
		NOTIFICATIONS: {
			NOTIFICATIONS: 'Notifiche',
			TITLE: 'Titolo',
			MESSAGE: 'Messaggio',
			SEND: {
				BUTTON_LABEL: 'Inviato',
				SUCCESS: 'Invio notifica avvenuto con successo',
				ERROR: 'Invio notifica fallito'
			},
			TYPE: {
				ALL_ACCOUNTS: 'Tutti gli account',
				ALL_USERS: 'Tutti gli utenti',
				ALL_RIDERS: 'Tutti i trasportatori',
				ALL_BUSINESS: 'Tutti i business',
				ALL_IOS: 'Tutti gli iOS',
				ALL_ANDROID: 'Tutti gli android',
			}
		},
		FEEDBACKS: {
			FEEDBACK: 'Feedback',
			FEEDBACKS: 'Feedback',
			FEEDBACKS_LIST: 'Lista Feedback',
			FIELDS: {
				TOT_FEEDBACK: 'Voto Medio',
				COMMENT: 'Commenti',
				USER: 'Utente',
				REQUIRED: 'Richiesto',
				TIMING: 'Tempo di consegna',
				QUALITY: 'Qualità',
				PACKAGE: 'Packaging'
			},
			ACTIONS: {
				APPROVE: 'Approvato',
				REFUSE: 'Rifiutato',
				APPROVED_MESSAGE: 'Feedback approvato',
				REFUSED_MESSAGE: 'Feedback rifiutato',
			},
			STATUS: {
				NEW: 'Nuovo',
				VOTED: 'Votato',
				APPROVED: 'Approvato',
				REFUSED: 'Rifiutato',
			}
		},
		PHONE_FORMAT_ERROR: 'Formato del numero di telefono errato',
		CROP: 'Ritaglia'
	}
};
