import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrdersModel } from '@app/core/models/orders.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class OrdersService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createOrder(order: OrdersModel): Observable<OrdersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}`;
		return this.http.post<OrdersModel>(url, order);
	}

	// READ
	getOrders(): Observable<OrdersModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}`;
		return this.http.get<OrdersModel[]>(url);
	}

	getBusinessOrders(): Observable<OrdersModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_BUSINESS_ORDERS}`;
		return this.http.get<OrdersModel[]>(url);
	}

	getOrderById(orderId: string): Observable<OrdersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}/${orderId}`;
		return this.http.get<OrdersModel>(url);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findOrders(queryParams: QueryParamsModel, role: string): Observable<QueryResultsModel> {
		//console.log('findOrders', queryParams)

		let params: string = '';
		if (queryParams.filter.date) {
					params += `dt_order|>=|${queryParams.filter.date}`;
		} else {
			var today = new Date();
			let formatted_date = today.getFullYear() + "-";
			formatted_date += (today.getMonth() + 1) > 9 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1);
			formatted_date += "-";
			formatted_date += (today.getDate() > 9) ? today.getDate() : '0' + today.getDate();
			params += `dt_order|>=|${formatted_date}`;
		}
		if (queryParams.filter.status) {
			if (params.length > 0) params += ',';
			params += `cd_state|==|${queryParams.filter.status}`;
		}
		if (queryParams.filter.city) {
			if (params.length > 0) params += ',';
			params += `business.id_city|==|${queryParams.filter.city}`;
		}
		if (queryParams.pageNumber) {
			if (params.length > 0) params += '&';
			params += `page_index=${queryParams.pageNumber}`;
		} else {
			if (params.length > 0) params += '&';
			params += `page_index=0`;
		}
		if (queryParams.pageSize) {
			if (params.length > 0) params += '&';
			params += `page_size=${queryParams.pageSize}`;
		} else {
			if (params.length > 0) params += '&';
			params += `page_size=10`;
		}
		if (queryParams.filter.orderby) {
			if (params.length > 0) params += '&';
			params += `orderby=${queryParams.filter.orderby}`;
		} else {
			if (params.length > 0) params += '&';
			params += `orderby=dt_order|asc`;
		}
		let url: string;
		switch (role) {
			case 'ADMIN':
				url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}`;
				break;
			case 'STAFF':
				url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}`;
				break;
			case 'BUSINESS':
				url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}`;
				break;
		}
		if (params.length > 0) {
			url = `${url}?where=${params}`;
		}
		//console.log('findOrders', url);
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the order on the server
	updateOrder(order: OrdersModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}/${order.id}`;
		return this.http.put(url, order);
	}

	// DELETE => delete the order from the server
	deleteOrder(orderId: string): Observable<OrdersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}/${orderId}/cancel`;
		return this.http.put<OrdersModel>(url, null);
	}

	// ACTIVATE => activate the order from the server
	activateOrder(orderId: string): Observable<OrdersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}/${orderId}/activate`;
		return this.http.delete<OrdersModel>(url);
	}

	// DEACTIVATE => deactivate the order from the server
	deactivateOrder(orderId: string): Observable<OrdersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}/${orderId}/deactivate`;
		return this.http.delete<OrdersModel>(url);
	}

	assignRider(orderId: string, assignment: OrdersModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_ORDERS}/${orderId}/assign-rider`;
		return this.http.put(url, assignment);
	}

}
