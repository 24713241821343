import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { StaffModel } from '@app/core/models/staff.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class StaffService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createStaff(staff: StaffModel): Observable<StaffModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}`;
		return this.http.post<StaffModel>(url, staff);
	}

	// READ
	getStaff(staff: StaffModel): Observable<StaffModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}`;
		return this.http.get<StaffModel[]>(url);
	}

	getStaffById(staffId: string): Observable<StaffModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}/${staffId}`;
		return this.http.get<StaffModel>(url);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findStaff(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}`;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the staff on the server
	updateStaff(staff: StaffModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}/${staff.uid}`;
		return this.http.put(url, staff);
	}

	// DELETE => delete the staff from the server
	deleteStaff(staffId: string): Observable<StaffModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}/${staffId}`;
		return this.http.delete<StaffModel>(url);
	}

	// ACTIVATE => activate the staff from the server
	activateStaff(staffId: string): Observable<StaffModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}/${staffId}/activate`;
		return this.http.put<StaffModel>(url, '');
	}

	// DEACTIVATE => deactivate the staff from the server
	deactivateStaff(staffId: string): Observable<StaffModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_STAFF}/${staffId}/deactivate`;
		return this.http.put<StaffModel>(url, '');
	}

	resetPassword(email: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION}/reset-password`;
		return this.http.post(url, { 'ds_email': email });
	}

}
