import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { CuisineTypesModel } from '@app/core/models/cuisine-types.model.ts';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

//import { CuisineTypesDataSource } from '@app/core/models/data-sources/cuisine-types.datasource';

import { API } from '@env/environment';

@Injectable()
export class CuisineTypesService {

	//private dataSource: CuisineTypesDataSource;
	public cuisineTypesResult: any;
	//public bResult$: Observable<CuisineTypesModel[]>;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
		/*
		if (!this.cuisineTypesResult) {
			this.bResult$ = this.getCuisineTypes();
			this.bResult$.subscribe( (res: any) => {
				this.cuisineTypesResult = res;
			})
		}
		*/
	}

	// CREATE =>  POST: add a new service to the server
	createCuisineType(cuisineType: CuisineTypesModel): Observable<CuisineTypesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES}/${cuisineType.id}`;
		return this.http.post<CuisineTypesModel>(url, cuisineType);
	}

	// READ
	getCuisineTypes(): Observable<CuisineTypesModel[]> {
		return this.http.get<CuisineTypesModel[]>(API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES);
	}

	getCuisineTypeNameById(serviceId: string, currentLanguage: string) {
		let name: string = '';
		for (const service of this.cuisineTypesResult) {
			if (service.id === serviceId) {
				return service[`ds_name_${currentLanguage}`];
			}
		}
		return name;
	}

	getCuisineTypeById(serviceId: string) {
		let _service: any;
		for (const service of this.cuisineTypesResult) {
			if (service.id === serviceId) {
				return service;
			}
		}
		return _service;
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findCuisineTypes(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES;
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the customer on the server
	updateCuisineType(cuisineType: CuisineTypesModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES}/${cuisineType.id}`;
		return this.http.put(url, cuisineType);
	}

	// DELETE => delete the customer from the server
	deleteCuisineType(serviceId: string): Observable<CuisineTypesModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES}/${serviceId}`;
		return this.http.delete<CuisineTypesModel>(url);
	}

	deleteCuisineTypes(ids: string[] = []): Observable<any> {
		const url = API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES + '/deletecuisinetypes';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { cuisinetypesIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}

	// UPDATE Status
	updateStatusForCuisineTypes(cuisinetypes: CuisineTypesModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			cuisinetypesForUpdate: cuisinetypes,
			newStatus: status
		};
		const url = API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	uploadImage(cuisinetypesId: string, image: File) {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API.API_URL + API.API_VERSION + API.API_CUISINE_TYPES}/${cuisinetypesId}/image/upload`;
		return this.http.post(url, formData);
	}
}
