// Import bugsnag-js and bugsnag-angular
import bugsnagClient from '@app/bugsnag';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { environment, gmaps_api } from '@env/environment';

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
//import { HttpClientModule } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MadMaxInterceptor } from './core/services/madmax.interceptor';

import 'hammerjs';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthenticationModule } from './core/auth/authentication.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
//import { FakeApiService } from './fake-api/fake-api.service';

import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { CoreModule } from './core/core.module';
import { AclService } from './core/services/acl.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { PageConfigService } from './core/services/page-config.service';
//import { UserService } from './core/services/users.service';
import { UtilsService } from './core/services/utils.service';
import { ClassInitService } from './core/services/class-init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';

import { MessengerService } from './core/services/messenger.service';
import { ClipboardService } from './core/services/clipboard.sevice';

import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LogsService } from './core/services/logs.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { HeaderService } from './core/services/layout/header.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { DataTableService } from './core/services/datatable.service';

// Categories
import { BusinessServicesService } from '@app/core/services/business-services.service';
import { DishesCategoriesService } from '@app/core/services/dishes-categories.service';
import { CuisineTypesService } from '@app/core/services/cuisine-types.service';
// Restaurants
import { RestaurantsService } from '@app/core/services/restaurants.service';
// Requests
import { RequestsService } from '@app/core/services/requests.service';
// Customizations
import { CustomizationsService } from '@app/core/services/customizations.service';
// Dishes
import { DishesService } from '@app/core/services/dishes.service';
// Restaurant
import { BusinessService } from '@app/core/services/business.service';
// Cities
import { CitiesService } from '@app/core/services/cities.service';
// MeetingPoints
import { MeetingPointsService } from '@app/core/services/meetingpoints.service';
// Menu
import { MenuService } from '@app/core/services/menu.service';
// Riders
import { RidersService } from '@app/core/services/riders.service';
// Riders
import { UsersService } from '@app/core/services/users.service';
// Orders
import { OrdersService } from '@app/core/services/orders.service';
// Feedbacks
import { FeedbacksService } from '@app/core/services/feedbacks.service';
// Staff
import { StaffService } from '@app/core/services/staff.service';
// AGM (ANGULAR GOOGLE MAPS)
import { GeocodeService } from '@app/core/services/geocode.service';
// SETTINGS
import { SettingsService } from "@app/core/services/settings.service";
//
import { HttpUtilsService } from '@app/content/pages/components/apps/e-commerce/_core/utils/http-utils.service';

import { NotificationsService } from '@app/core/services/notifications.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};

// Firebase
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { MessagingService } from "@app/core/services/messaging.service";

// Static Collections
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

import { DashboardService } from '@app/core/services/dashboard.service';
import { StatsService } from '@app/core/services/stats.service';

import { AgmCoreModule } from '@agm/core';
//import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		//environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService) : [],
		AgmCoreModule.forRoot({
			apiKey: gmaps_api.apikey
		}),
		LayoutModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		NgxPermissionsModule.forRoot(),
		NgbModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		AngularFireModule.initializeApp(environment.firebase),
	  AngularFirestoreModule, // imports firebase/firestore, only needed for database features
	  AngularFireAuthModule, // imports firebase/auth, only needed for auth features
		AngularFireMessagingModule//, TypeaheadModule.forRoot()
	],
	providers: [
		{ provide: ErrorHandler, useFactory: errorHandlerFactory },
		AclService,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		//UserService,
		UtilsService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		LogsService,
		QuickSearchService,
		DataTableService,
		{ provide: HTTP_INTERCEPTORS, useClass: MadMaxInterceptor, multi: true },
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
    RestaurantsService,
		StaticCollectionsService,
    DashboardService,
    StatsService,
		BusinessServicesService,
		DishesCategoriesService,
		CuisineTypesService,
		RequestsService,
		CustomizationsService,
		DishesService,
		BusinessService,
		CitiesService,
		MeetingPointsService,
		MenuService,
		RidersService,
		UsersService,
		OrdersService,
    FeedbacksService,
		GeocodeService,
		StaffService,
		SettingsService,
		//MapsAPILoader,
		HttpUtilsService,
		//
		MessagingService,
		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
    NotificationsService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
