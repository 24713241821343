import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { UsersModel } from '@app/core/models/users.model';
import { QueryParamsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

import { AuthenticationService } from '@app/core/auth/authentication.service';

import { API } from '@env/environment';

@Injectable()
export class UsersService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient, private auth: AuthenticationService) { }

	// CREATE =>  POST: add a new service to the server
	createUser(user: UsersModel): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}`;
		return this.http.post<UsersModel>(url, user);
	}

	// READ
	getUsers(user: UsersModel): Observable<UsersModel[]> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}`;
		return this.http.get<UsersModel[]>(url);
	}

	getUserById(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}`;
		return this.http.get<UsersModel>(url);
	}

	searchUser(text: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}?q=${text}`;
		return this.http.get<any>(url);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		//console.log('findUsers', queryParams);
		let params:string = '';
		if ( queryParams.pageNumber ) {
			params += `?page_index=${queryParams.pageNumber}`;
		} else {
			params += `?page_index=0`;
		}
		if ( queryParams.pageSize ) {
			params += `&page_size=${queryParams.pageSize}`;
		} else {
			params += `&page_size=10`;
		}
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS + params}`;
		//console.log('findUsers', url);
		return this.http.get<QueryResultsModel>(url);
	}

	// UPDATE => PUT: update the user on the server
	updateUser(userId: string, user: UsersModel): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}`;
		return this.http.put(url, user);
	}

	// DELETE => delete the user from the server
	deleteUser(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}`;
		return this.http.delete<UsersModel>(url);
	}

	// ACTIVATE => activate the user from the server
	activateUser(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}/activate`;
		return this.http.put<UsersModel>(url, {});
	}

	// DEACTIVATE => deactivate the user from the server
	deactivateUser(userId: string): Observable<UsersModel> {
		const url = `${API.API_URL + API.API_VERSION + API.API_USERS}/${userId}/deactivate`;
		return this.http.put<UsersModel>(url, {});
	}

	resetPassword(email: string): Observable<any> {
		const url = `${API.API_URL + API.API_VERSION}/reset-password`;
		return this.http.post(url, { 'ds_email': email });
	}

}
